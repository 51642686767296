import { useEffect } from "react";
import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Container } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import CardWrapper from "../../components/general/CardWrapper";
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from "../../services/api";
import './UserProfile.css';
import { 
  validatePassword, 
  validateName,
  validatePhone,
} from '../../services/validations';
import Table from 'react-bootstrap/Table';
import InputFieldPhone from '../../components/general/InputFieldPhone';
import look from "../../lib/img/eyeShow.svg";
import lookClose from "../../lib/img/eyeHide.svg";

function UserProfile() {
  const [user, setUser] = useState({ email: "", companyName: "", licenseId: "" });
  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  useEffect(() => {
    async function fetchData() {
      const userData = await API.get("user/userinfo");

      if (userData.status === 200) {
        setUser(userData.data);
        if (userData.data.phoneNumber) {
          setPhone(userData.data.phoneNumber);
        }
        if (userData.data.userName) {
          setUserName(userData.data.userName);
        }
      }
    }

    fetchData();
  }, []);

  const handleChangeUserName = () => {
    setErrorMessage(null); setSuccessMessage(null);
    let errorMess = null;
    if(userName != null && userName != "")
      errorMess = validateName(userName);
    else
      errorMess = "Поле не може бути порожнім";

    if(errorMess != null){
      setErrorMessage(errorMess);
      return;
    }

    API.put("user/user_name/" + userName)
    .then((response) => {
      if (response.status === 200 || response.statusCode === 200) {
        let showAllPagMessage = "ПІБ збережено!";
        setSuccessMessage(showAllPagMessage);
      }
    })
    .catch((error, response) => {
    let message;
      if (error.response) {
        message = error.response.statusText;
      if (error.response.data != "") {
        message = error.response.data.message;
      }
      } else if (error.request && error.response.data != "") {
        message = error.request.data;
      }
      setErrorMessage(message);
    });
  };
  const handleChangePhone = () => {
    setErrorMessage(null); setSuccessMessage(null);
    let errorMess = null;
    if(phone != null && phone != "")
      errorMess = validatePhone(phone);
    else
      errorMess = "Поле не може бути порожнім";

    if(errorMess != null){
      setErrorMessage(errorMess);
      return;
    }
    
    API.put("user/phone/" + phone)
    .then((response) => {
      if (response.status === 200 || response.statusCode === 200) {
        let showAllPagMessage = "Телефон збережено!";
        setSuccessMessage(showAllPagMessage);
      }
    })
    .catch((error, response) => {
    let message;
      if (error.response) {
        message = error.response.data.message;
      } else if (error.request) {
        message = error.request.data;
      }
      setErrorMessage(message);
    });
  };

  const handleChangePassword = () => {
    setErrorMessage(null); setSuccessMessage(null);
    let errorMess = null;
    if(password != null && password != "")
      errorMess = validatePassword(password);
    else
      errorMess = "Поле не може бути порожнім";

    if(errorMess != null){
      setErrorMessage(errorMess);
      return;
    }
    
    if(user?.email != null && password != null && !validatePassword(password))
     {
      API.post('account/password_reset', {
      email: user?.email,
      password: password,
      })
      .then((response) => {
        if (response.status === 200 || response.statusCode === 200) {
          let showAllPagMessage = "Новий пароль збережено та відправлено на пошту "+ user?.email +"!";
          setSuccessMessage(showAllPagMessage);
          setPassword(null);
          if(document.getElementById("form-control-user-pass") != null && document.getElementById("form-control-user-pass").value != null)
          {document.getElementById("form-control-user-pass").value = null}
        }
      })
      .catch((error, response) => {
      let message;
        if (error.response) {
          message = error.response.statusText;
          if (error.response.data != "") {
            message = error.response.data.message;
          }
        } else if (error.request) {
          message = error.request.data;
        }
        setErrorMessage(message);
      });
    };
  };

  return (
    <div className="App">
      <Container className='mw-100'>
        <Breadcrumbs />
        <Container className="shadow-none">
        <Card>
          <Card.Body>
          <Card.Title>Профіль користувача</Card.Title>
          <CardWrapper theme="light" idCard="idCard" idTitle="idTitle">
            <Table className='additional-info-table' responsive="lg">
              <tbody>
              <tr>
                  <td> Email</td>
                <td colspan="2"> {user?.email} </td>
              </tr>
              <tr>
                  <td> ПІБ</td>
                <td ><div className="MuiInput-underline MuiInput-root ">
                <Form.Control className="rounded-0" placeholder="Введіть прізвище, ім'я та по батькові" id="form-control-name" validation={validateName} value={userName} onChange={(e) => setUserName(e.target.value)} />
                </div></td>
                  <td> 
                  <Button variant="primary" id="btn-primary-user" onClick={handleChangeUserName}>Змінити</Button>
                  </td>
              </tr>
              <tr className="trPhone">
                  <td> Телефон</td>
                <td className="tdPhone">
                <InputFieldPhone
                    name="phone"
                    type="phone"
                    setPhone={setPhone}
                    phone={phone}
                  />
                {/* <Form.Control  placeholder="+38 ___ ___ __ __" id="form-control-user" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
                </td>
                  <td> 
                  <Button variant="primary" id="btn-primary-user" onClick={handleChangePhone}>Змінити</Button>
                  </td>
              </tr>
              <tr>
                <td> Пароль</td>
                <td className="tdLook"><div className="MuiInput-underline MuiInput-root ">
                <Form.Control className="rounded-0"  id="form-control-user-pass" type={showPassword} value={password} onChange={(e) => { setPassword(e.target.value); 
                    if(e.target.value) { setErrorMessage(validatePassword(e.target.value)); } else {setErrorMessage(null);} setSuccessMessage(null);
                    }} />
                </div>
                {showPassword === "password"? 
              <img src={lookClose} className="btn btn-primary-look-UserProfile " onClick={() => {setShowPassword("text");}}></img>:
              <img src={look} className="btn btn-primary-look-UserProfile " onClick={() => {setShowPassword("password");}}></img>  
              }
                </td>
                <td> 

                  {/* <Button variant="primary"  className="btn btn-primary-look-UserProfile btn-sm" onClick={() => { if(showPassword === "password") {setShowPassword("text");} else {setShowPassword("password");} }}></Button> */}
                  <Button variant="primary" id="btn-primary-user" onClick={handleChangePassword}>Змінити</Button>
                </td>
              </tr>
              </tbody>
            </Table>
              {errorMessage ? <div className="text-danger my-2 errorMessageRed" >{errorMessage}</div> : null}
              {successMessage ? <div className="text-success my-2 successMessage" >{successMessage}</div> : null}
          </CardWrapper>
          </Card.Body>
        </Card>
      </Container>
      </Container>
    </div>
  );
}

export default UserProfile;