import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { validateERDPOU, validateMessage } from '../../services/validations';
import { Link } from 'react-router-dom';
import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import fileImage from '../../lib/img/fileImage.png';

function MessageFormSend({ id, onSubmit, handleClose, errorMessage, selectedFiles, setFiles }) {

    const [fileError, setFileError] = useState(null);

    const selectedFilesName = async (files) => {
      const selectedInnputFiles = document.getElementById("selectedInnputFiles");
      let selectedFilesName = [];
      for (let file of files) {
        const base64 =  await convertBase64(file);
        selectedFilesName.push({name: file.name, type: file.type, base64: base64 });
      }

      selectedInnputFiles.innerHTML = (selectedFilesName.map((item, index) => {
            return `
                <div className="row">
                    <div className="col">
                      <option>
                        ${item.name}
                      </option>
                      <img type=${item.type} src=${item.base64} height="48px" />
                    </div>
                </div>`;
      }
      ).join(''));
    }
   

    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
  
          fileReader.onload = () => {
              resolve(fileReader.result);
          };
  
          fileReader.onerror = (error) => {
              reject(error);
          };
      });
  };

    // const onFileChanges = (e) => {}
    const onFileChanges = async (e) => {
      let error = '';
  
      if (e.target.files.length <= 3) {
        for (let file of e.target.files) {
          const fileSize = file.size / (1024 * 1024); // Size in mb
          const fileExt = (file.name.substring(file.name.lastIndexOf('.')+1, file.name.length)); 
          if (fileSize > 10) {
            error = 'Розмір файлу не може бути більше 10 Мбайт';
            break;
          }
          if (fileExt.toLowerCase() != 'jpg' && fileExt.toLowerCase() != 'jpeg' ) {
            error = 'Тип фото може бути тільки jpg/jpeg';
            break;
          }
        }
      } else {
        error = 'Максимальна кількість файлів 3';
      }
  
      setFileError(error);
  
      let files = null;
      if (!error) {
        files = e.target.files;
      }
      setFiles(files);
      
      if (files) await selectedFilesName(files);
    };

    const onDrop = useCallback((acceptedFiles) => {
      let error = '';
  
      if (acceptedFiles.length <= 3) {
        for (let file of acceptedFiles) {
          const fileSize = file.size / (1024 * 1024); // Size in mb
          const fileExt = (file.name.substring(file.name.lastIndexOf('.')+1, file.name.length)); 
          if (fileSize > 10) {
            error = 'Розмір фото не може бути більше 10 Мбайт';
            break;
          } 
          if (fileExt != 'jpg' || fileExt != 'jpeg' ) {
            error = 'Тип фото може бути тільки jpg/jpeg';
            break;
          }
        }
      } else {
        error = 'Максимальна кількість фото 3';
      }
  
      setFileError(error);
  
      let files = null;
      if (!error) {
        files = acceptedFiles;
      }
      setFiles(files);
  
    }, [])
  
    const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <Formik
      initialValues={{
        description: ''
      }}
      onSubmit={onSubmit}
    >
      {({ touched, errors }) => (
        <Form className='text-align-left'>
          <FormGroup >
            <FormLabel>Коментар<span className="text-danger"><sup>*</sup></span>:</FormLabel>
            <Field
            as="textarea"
            name="description"
            id="description"
            placeholder="Введіть коментар"
            rows={10} 
            className={"border form-control " + (touched.description && errors.description ? "is-invalid" : "")}
            validate={validateMessage}
            />
            {touched.description && errors.description ? <span className="text-danger">{errors.description}</span> : null}
            {/* is-invalid */}
          </FormGroup>  
          <FormGroup>
            {fileError && <div className="text-danger">{fileError}</div>}
            <FormLabel><span>Фото (максимальний розмір 10Мб)</span></FormLabel>
            {/* <input className="request-form-file d-none" type="file" id="files" multiple onChange={onFileChanges} {...getInputProps()} /> */}
            <input className="request-form-file d-none" type="file" id="files" accept="image/jpg, image/jpeg"  multiple onChange={onFileChanges} />
            {/* <div className="drop-area" {...getRootProps()}> */}
            <div className="drop-area">
              {/* <div {...getRootProps()}>
                <input  />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div> */}
              <label htmlFor="files" className="request-file-label">
                <span>Обрати файл</span>
                <img src={fileImage} alt="fileImage" />
              </label>
              {/* <span>{selectedFiles && "Файлiв: " + selectedFiles.length}</span> */}
              <span id='selectedInnputFiles'></span>
            </div>
          </FormGroup>
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
          <div className='text-align-right border-top py-2 button-submit-send'>
            <Button className="form-btn" type="submit">
              Ок
            </Button>
            <Link className="form-btn" onClick={handleClose}>
              Скасувати
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default MessageFormSend;
