import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router';
import CompanyCreate from '../../pages/Company/CompanyCreate';
import CompanyEdit from '../../pages/Company/CompanyEdit';
import CompanyList from '../../pages/Company/CompanyList';
import CompanyView from '../../pages/Company/CompanyView';
import NotFound from '../../pages/NotFound/NotFound';
import UserList from '../../pages/UsersList/UsersList';
import PrivateRoute from '../general/PrivateRoute';
import { useAuth } from '../general/ProvideAuth';
import { NavigationBar, FooterBar } from '../Navbar/Index';
import UserProfile from '../../pages/UserProfile/UserProfile';
import  Breadcrumbs from '../../services/Breadcrumbs';
import './AdminLayout.css';

function AdminLayout() {
  const auth = useAuth();

  return auth.user.role !== 'admin' ? (
    <Redirect to="/" />
  ) : (
    <>
    <NavigationBar />
    <Switch>
      <PrivateRoute exact path="/admin/users">
        <div className="App">
          <Container className='mw-100'>
            <Breadcrumbs />
            <UserList />
          </Container>
        </div>
      </PrivateRoute>
      {/* <PrivateRoute exact path="/admin/users/:id">
        <CompanyView />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/users/edit/:id">
        <CompanyEdit />
      </PrivateRoute> */}
      <PrivateRoute exact path="/admin">
        <UserProfile />
      </PrivateRoute>
      <PrivateRoute path="/">
        <Redirect to="/admin" />
      </PrivateRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
    <FooterBar />
    </>
  );
}

export default AdminLayout;
