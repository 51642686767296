import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import help from '../../lib/img/help_242210.svg';
import help_pls_all from '../../lib/pdf/Help_pls_all.pdf';
import help_pls_adm from '../../lib/pdf/Help_pls_adm.pdf';
import help_pls_user from '../../lib/pdf/Help_pls_user.pdf';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from './ProvideAuth';
import './UserWidged.css';

function UserWidget() {
  const auth = useAuth();
  return (
    <>
      {auth && auth.user ? (
        <Nav className="">
          <NavDropdown className="navbar-nav" title={auth.user?.email} id="basic-nav-dropdown">
            {auth.user.role === 'admin' ? (
              <>
                <Link className="dropdown-item" to="/admin">
                  Профіль
                </Link>
                <NavDropdown.Divider />
                <Link className="dropdown-item" to="/admin/users">
                  Користувачі
                </Link>
              </>
            ) : (
              <Link className="dropdown-item" to="/user">
                Профіль
              </Link>
            )}
            <NavDropdown.Divider />
            <Link className="dropdown-item" to="/account/signout">
              Вийти
            </Link>
          </NavDropdown>
          {auth.user.role === 'admin' ? (
              <NavLink to={help_pls_adm} target="_blank" className="nav-guest-item" activeClassName="active">
              <img src={help}/>
              </NavLink>
            ) : (
              <NavLink to={help_pls_user} target="_blank" className="nav-guest-item" activeClassName="active">
              <img src={help}/>
              </NavLink>
            )}
        </Nav>
        ) : (
          <Nav className="ml-auto">
            <NavLink to="/account/signin" className="form-guest-navbar-login" id='' activeClassName="active">
              Увійти
            </NavLink>
            <NavLink to={help_pls_all} target="_blank" className="nav-guest-item" activeClassName="active">
            <img src={help}/>
            </NavLink>
          </Nav>
        )}
    </>
  );
}

export default UserWidget;
