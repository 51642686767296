import { useState } from 'react';
import { useAuth } from '../general/ProvideAuth';
import { Link } from 'react-router-dom';
import API from '../../services/api';
import MessageFormSend from './MessageFormSend';
import Modal from 'react-bootstrap/Modal';

function ModalFormAdd(id) {
  const auth = useAuth();

  const [show, setShow] = useState(false);
  const [selectedFiles, setFiles] = useState(null);
  const [errorMessageSend, setErrorSend] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  function handleAdd(values) {

    if(values.description.length>0)
    {
      API.post(`/user/message`, {
        MessageText: values.description,
        ParentDocument: id
      })
        .then((res) => {
          setErrorSend("");
          if (selectedFiles) {
            const formData = new FormData();
            for (let file of selectedFiles) {
              formData.append('files', file);
            }
            API.post('/file/message?ParentDocumentId=' + id + (res?.data?"&MessagesId=" + res.data.id:""), formData).then(() => {
              window.location.reload(true);
            });
          }else
          {
            window.location.reload(true);
          }
        })
        .catch((error) => {
          if (error.response?.data?.title) {
            setErrorSend(` ${error.response?.data?.title}`);
          }
        });
    }
  }
  
  function ModalForm() {
    return (
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Додати</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <MessageFormSend type="view" 
              id={id} 
              onSubmit={handleAdd}
              handleClose={handleClose}
              errorMessage={errorMessageSend}
              selectedFiles={selectedFiles} 
              setFiles={setFiles} 
            />
          </Modal.Body>
        {/* <Modal.Footer>
        </Modal.Footer> */}
      </Modal>
    );
  }

  if(auth && auth.user){
    return ( <>
          {ModalForm()}
          <Link className="form-btn ml-2" onClick={handleShow}>
            Додати
          </Link>
        </>);
  }

  return (<></>);
}

export default ModalFormAdd;
