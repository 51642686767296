import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import './DiagramAnalysisCount.css';
import { Form } from 'react-bootstrap';
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from '../../services/api';
import  moment from 'moment';
import {getDiagramBarHorizontal, getDiagramBarVertical} from './DiagramAnalysisCountBar';
import { requestDistricts } from '../../services/requestService';
// import {getMyResponsiveBar} from '../../services/BarCanvas';

function Diagram() {
  const [diagram, setDiagram] = useState([]);
  const [districts, setDistricts] = useState(requestDistricts[0]);
  const { page } = useParams();//NormalizeDateView(
  const [dateFilter, setDateFilter] = useState(moment().format('DD.MM.YYYY'));
  const auth = useAuth();

  useEffect(() => {
    async function fetchData() {
      await getData();
    }

    fetchData();
  }, [page, districts, dateFilter]);
  
  async function getData() {
    const result = await API.get('/guest/AnalysisReestration', {
      params: {
        Date : dateFilter,
        AddressAdminUnit: districts
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
      else return [];
    }).catch(() => {
      return [];
    });

    let sd = (diagram);
    sd.length= 0;
    if (diagram && diagram.length > 0) 
    {
      if (result && result.length > 0)
      {
        if (result.length == 1)
        {
          setDiagram([result[0]]);
        }else{
          setDiagram(result);
        }
      }else
        setDiagram(sd);
    }else
      setDiagram(result);
  }

  return (
    <div className="App">
      <Container className='mw-100 px-0'>
      <div className='form-guest-bg-gradient'>
          <Container className='navbar-guest-title mw-100 d-flex justify-content-center shadow-none align-items-center'>
            <div className='align-middle'>
              {/* <Link to="/" className='text-center'> */}
                <h1>Динаміка обробки дозволів</h1>
              {/* </Link> */}
            </div>
          </Container>
        </div>
        <Breadcrumbs />
        <div className="d-flex flex-column align-items-center">
          <div className="header-bar">
            <Form.Group className='form-group-bar justify-content-center d-flex w-100 flex-wrap'>
              {/* <Form.Control id="filter" as="select" value={selectedStatus} onChange={(e) => setStatus(e.target.value)}> */}
              На <Form.Control 
              type="date" 
              className='mr-4 rounded-0 form-control-element-w10' 
              value={moment(dateFilter, 'DD.MM.YYYY').format('YYYY-MM-DD')}
              onChange={(e) =>  {e.target.value && setDateFilter(moment(e.target.value).format('DD.MM.YYYY'), getData())}}
              /> по
              <Form.Control as="select" placeholder="" aria-label="Search"
                className="me-2 mr-4 rounded-0 form-group-bar-search form-control-element-w16"
                onChange={(e) => setDistricts(e.target.value)}
                > 
                <option value="-1" disabled>
                  Виберіть район
                </option>
                {requestDistricts.map((p) => (
                  <option key={p} value={p}>
                    {p}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="my-1 col-md-10">
              <div className="d-flex diagrams-container-box"> 
                  <h4>Станом з початку року по {dateFilter}</h4>
              </div>
          </div>
          <div className="my-2 col-md-10">
            <div className='diagrams-container diagrams'>
              <div className="diagrams col-md-10"> 
                {getDiagramBarHorizontal(diagram)}
              </div>
            </div>
            <div className="d-flex flex-wrap">
              <div className='diagram-legend-bar-container'>
                <div className='d-flex mt-2'><div className='diagram-legend-bar-f'></div>ЦЕНТР АДМІНІСТРАТИВНИХ ПОСЛУГ "ВІЗА" ("ЦЕНТР ДІЇ")</div>
                <div className='d-flex mt-2'><div className='diagram-legend-bar-s'></div>Муніципальні онлайн-послуги</div>
              </div>
              <div className=" diagrams-box-container-min col-md-6 col-sm-12 col-12 p-0">
                <div className='mt-4 diagrams-container-min'>{getDiagramBarVertical(diagram, moment(dateFilter, 'DD.MM.YYYY').year())}</div>
              </div>
            </div>
              {/* <div className="paginator"><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/1" className="active">1</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/2">2</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/3">3</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/4">4</a></span><span>...<a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/722">722</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/723">723</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/724">724</a></span></div> */}
          </div>
        </div>
      </Container>
    </div>

  );

}

export default Diagram;
