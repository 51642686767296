export const requestInvariantLevels = ['regulatory', 'planning', 'normal', 'critical'];
export const requestLevels = ['Регламентні', 'Планова', 'Звичайний', 'Критичний'];
export const requestStatuses = ['Відкрито', 'В обробці', 'Закрито', 'Вирішено'];
export const requestDistricts = ['Кривий Ріг', 'Довгинцівський', 'Інгулецький', 'Металургійний', 'Покровський', 'Саксаганський', 'Тернівський', 'Центрально-Міський'];

export function getStatus(i) {
  return requestStatuses[i];
}

export function getLevel(i) {
  return requestLevels[i];
}

export function getLevelInvariant(i) {
  return requestInvariantLevels[i];
}
