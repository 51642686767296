// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
// import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const axis ={
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40
}
// const Diagram = (data, keys, indexBy,layout,axisTop,axisRight,axisBottom,axisLeft) => (
//     <ResponsiveBar
//         data={data}
//         keys={keys}
//         indexBy={indexBy}
//         margin={{ top: 30, right: 30, bottom: 30, left: 60 }}
//         padding={0.4}
//         layout={layout}
//         indexScale={{ type: 'band', round: true }}
//         colors={{ scheme: 'purpleRed_green' }}
//         axisTop={axisTop}
//         axisRight={axisRight}
//         axisBottom={axisBottom}
//         axisLeft={axisLeft}
//         enableGridY={false}
//         legends={[]}
//         ariaLabel="Diagram"
//     />
// );

const Diagram = ( data ) => (
    <ResponsivePie
        data={data}
        margin={{ top: 5, right: 50, bottom: 5, left: 50 }}
        startAngle={-90}
        endAngle={90}
        innerRadius={0.45}
        activeOuterRadiusOffset={8}
        colors="rgb(150, 209, 189)"
        borderWidth={1}
        borderColor="rgb(150, 209, 189)"
        // borderColor={{
        //     from: 'color',
        //     modifiers: [
        //         [
        //             'darker',
        //             '0.2'
        //         ]
        //     ]
        // }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ theme: 'background' }}
        defs={[
            {
                id: 'clear-space',
                type: 'patternDots',
                background: '#fff',
                color: 'rgba(255, 255, 255, 0.3)',
            },
            {
                id: 'lines',
                type: 'patternDots',
                background: 'rgb(184, 231, 229)',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        isInteractive={true}
        fill={[
            {
                match: {
                    id: data[1].id
                },
                id: data[1].defs
            },
            {
                match: {
                    id: data[0].id
                },
                id: data[0].defs
            }
        ]}
        legends={[]}
    />
);

// function getMonthName(monthNumber) {
//     const date = new Date();
//     date.setMonth(monthNumber - 1);
  
//     return date.toLocaleString('uk-UK', { month: 'long' });
//   }
// default 
const EsoyName ="ЦЕНТР АДМІНІСТРАТИВНИХ ПОСЛУГ \"ВІЗА\" (\"ЦЕНТР ДІЇ\")"; 
const PortalName ="Муніципальні онлайн-послуги"; 

export function getDiagramPie(startValue, startDefs, endValue, endDefs) {
    const data = [
    {
        id: "Портал ",
        value: startValue,
        color: "hsl(71, 70%, 50%)",
        defs: startDefs
    },
    {
        id: "ЦНАП ",
        value: endValue,
        defs: endDefs
    }
    ];
    return Diagram(data);
}

export function getDiagramPiePortal(diagramData) {
    if(!(diagramData)) return;
    let startValue = diagramData.countPortal; 
    let endValue = diagramData.countCnap; 

    const startDefs ='lines';
    const endDefs ='clear-space';
    return getDiagramPie(startValue, startDefs, endValue, endDefs);
}

export function getDiagramPieCnap(diagramData) {
    if(!(diagramData)) return;
    const startDefs ='clear-space';
    const endDefs ='lines';
    let startValue = diagramData.countPortal; 
    let endValue = diagramData.countCnap; 
    return getDiagramPie(startValue, startDefs, endValue, endDefs);
}

