const tokenName = 'SERVICE_TOKEN';
const roleProp = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

class JwtService {
  getToken() {
    return localStorage.getItem(tokenName);
  }

  setToken(token) {
    localStorage.setItem(tokenName, token);
  }

  removeToken() {
    localStorage.removeItem(tokenName);
  }

  getUser(token) {
    try {
      if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));

        let role;
        if (payload.role) role = payload.role;
        else if (payload[roleProp]) role = payload[roleProp];

        return {
          email: payload.email,
          role: role,
        };
      }
    } catch (error) {
      console.log(error);
      this.removeToken();
    }
    return null;
  }
}

export default new JwtService();
