import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CompanyForm from '../../components/Forms/CompanyForm';
import API from '../../services/api';

function CompanyCreate() {
  const history = useHistory();
  const [errorMessage, setError] = useState(null);

  function handleAdd(values) {
    API.post('/admin/company', {
      name: values.companyName,
      edrpou: values.edrpou,
    })
      .then(() => {
        history.push('/admin/company/list');
      })
      .catch((error) => {
        if (error.response?.data?.company) {
          setError(error.response.data.company.join(', '));
        }
      });
  }

  return (
    <Card>
      <Card.Header className="text-center text-dark-customizable h5">Додати замовника</Card.Header>
      <Card.Body>
        {errorMessage && <div className="text-danger">{errorMessage}</div>}
        <CompanyForm type="create" onSubmit={handleAdd} name="" edrpou="" />
      </Card.Body>
    </Card>
  );
}

export default CompanyCreate;
