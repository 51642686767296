export const MimeTypes = 
[
    { type: '.doc', value: 'application/msword'}, 
    { type: '.docx', value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}, 
    { type: '.bmp', value: 'image/bmp'}, 
    { type: '.jpeg', value: 'image/jpeg'}, 
    { type: '.jpg', value: 'image/jpeg'}, 
    { type: '.png', value: 'image/png'}, 
    { type: '.tif', value: 'image/tiff'}, 
    { type: '.tiff', value: 'image/tiff'}, 
    { type: '.webp', value: 'image/webp'}, 
    { type: '.pdf', value: 'application/pdf'}, 
];
