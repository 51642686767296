import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Pagination.css';

function Pagination({ page, pageCount, path }) {
  function pageItem(i) {
    return pageChar(i, i);
  }

  function pageChar(i, char) {
    return (
      <li key={i} className={`page-item ${Number.parseInt(page) === i ? 'active' : 'unactive'}`}>
        <Link className="page-link" to={path + i}>
          {char}
        </Link>
      </li>
    );
  }

  page = Number.parseInt(page);

  const pages = [];
  if (pageCount <= 3) {
    for (let i = 1; i <= pageCount; i++) {
      pages.push(pageItem(i));
    }
  } else {
    if (page >= 3) {
      pages.push(pageChar(1, '<'));
      pages.push(pageItem(1));
      if (page > 3) pages.push(pageChar(page - 2, '...'));
    }

    const startPage = page - 1 > 1 ? page - 1 : 1;
    const stopPage = page + 1 < pageCount ? page + 1 : pageCount;

    for (let i = startPage; i <= stopPage; i++) {
      pages.push(pageItem(i));
    }

    if (page + 2 <= pageCount) {
      if (page + 2 < pageCount) pages.push(pageChar(page + 2, '...'));
      pages.push(pageItem(pageCount));
    }
  }

  return (
    <Nav className="m-2 justify-content-center">
      <ul className="pagination">{pages}</ul>
    </Nav>
  );
}

export default Pagination;
