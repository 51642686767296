import { Container, Navbar } from 'react-bootstrap';
import './FooterBar.css';

function FooterBar() {
  return (
    <div variant="light" expand="lg" className="form-footerbar border-top box-shadow">
      <Container className='mw-100 text-center'>
        <a href="https://kai.ua" target="_blank" className='nav-item'>
          © KAI - розробник порталу
        </a>
      </Container>
    </div>
    // </div>
  );
}

export default FooterBar;
