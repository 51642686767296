import { Form, Formik, useFormikContext } from 'formik';
import { Button, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { validateEmail, validateRequired } from '../../services/validations';
import { useState } from 'react';
import { useAuth } from '../../components/general/ProvideAuth';
import CardWrapper from '../../components/general/CardWrapper';
import API from '../../services/api';
import InputField from '../../components/general/InputField';
import './Login.css';
import axios from 'axios';

function Login() {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showAllPagMessage, setShowAllPagMessage] = useState(null);
  const [allPageMessage, setAllPageMessage] = useState(null);
  const [showPassword, setShowPassword] = useState("password");

  const auth = useAuth();

  const handleSubmit = (values) => {
    API.post('account/login', {
      email: values.email,
      password: values.password,
    })
      .then((res) => {
        try {
          auth.login(res.data.token);
          history.push('/');
        } catch (error) {
        console.log(error);
      }
      })
      .catch((error) => {
        console.log(error);
        let message;
        if (error.response) {
          message = error.response.data.message;
        } else if (error.request) {
          message = error.request.data;
        }
        setErrorMessage(message);
      });
  };

  let resetPresForm = {};// You will have to define this before useEffect

  const ResettingForm = () => {
      const { resetForm } = useFormikContext();
      resetPresForm = resetForm; // Store the value of resetForm in this variable
      return null;
  };

  const handleSubmitReset = () => {
    if(document.getElementById("email") != null && document.getElementById("email").value != null)
     {
      const emaileTempSave = document.getElementById("email").value;

      API.post('account/password_reset', {
      email: emaileTempSave,
      })
      .then((response) => {
        if (response.status === 200 || response.statusCode === 200) {
          let showAllPagMessage = "Для входу згенеровано новий пароль та відправлено на пошту "+ emaileTempSave +"!";
          setAllPageMessage(true);
          setShowAllPagMessage(showAllPagMessage);
          resetPresForm({ values: { email: emaileTempSave, password: '' }});
          document.getElementById("FormikLogin").style.display = "none";
        }
      })
      .catch((error, response) => {
      let message;
        if (error.response) {
          message = error.response.data.message;
        } else if (error.request) {
          message = error.request.data;
        }
        setErrorMessage(message);
        if(message && message.includes("зареєстр")){
          setAllPageMessage(true);
          setShowAllPagMessage(message);
          resetPresForm({ values: { email: emaileTempSave, password: '' }});
          document.getElementById("FormikLogin").style.display = "none";
        }
      });

    }
  };

  // const handleSubmitReset = async() => {
  //   if (document.getElementById("email") != null && document.getElementById("email").value != null) {
  //     const emaileTempSave = document.getElementById("email").value;
  
  //     try {
  //      await axios.post(`http://localhost:5000/api/account/password_reset`, {
  //         email: emaileTempSave,
  //       })
  //       .then((response) => {
  //         if (response.status === 200 || response.statusCode === 200) {
  //           let showAllPagMessage = "Для входу згенеровано новий пароль та відправлено на пошту " + emaileTempSave + "!";
  //           setAllPageMessage(true);
  //           setShowAllPagMessage(showAllPagMessage);
  //           resetPresForm({ values: { email: emaileTempSave, password: '' } });
  //           document.getElementById("FormikLogin").style.display = "none";
  //         }
  //       })
  //       .catch((error) => {
  //         let message;
  //         if (error.response) {
  //           message = error.response.data.message;
  //         } else if (error.request) {
  //           message = error.request.data;
  //         }
  //         setErrorMessage(message);
  //         if (message && message.includes("зареєстр")) {
  //           setAllPageMessage(true);
  //           setShowAllPagMessage(message);
  //           resetPresForm({ values: { email: emaileTempSave, password: '' } });
  //           document.getElementById("FormikLogin").style.display = "none";
  //         }
  //       });
  //     } catch (error) {
  //       console.error('Error occurred:', error);
  //     }
  //   }
  // };

  const showCardWrapper = () => {
    if(allPageMessage){
      return (
        <CardWrapper>
        <h4>
          {showAllPagMessage}
        </h4>
        <div className="btn-login-conteiner">
          <a className="button btnOK" id="btn-login" onClick={()=>{setAllPageMessage(false);setErrorMessage(null); document.getElementById("FormikLogin").style.display = "block";}}>ОК</a>
        </div>
      </CardWrapper>
      );
    }
    }

  return (
    <div className='wrapFormik'>
      {showCardWrapper()}
      <div id="FormikLogin">
        <Card className="auth-card" id="auth-card-login">
          {/* <Card.Title className="auth-card-title">Будь ласка, авторизуйтесь</Card.Title> */}
          <span className='TitleLogin'>Будь ласка, авторизуйтесь...</span>
          <Card.Body className="card">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={handleSubmit}
            >
                  {({ errors, touched }) => (
                    <Form className='formLogin'>
                    {errorMessage && <div className="text-danger my-2">{errorMessage}</div>}
                    <InputField
                      name="email"
                      type="email"
                      touched={touched.email}
                      error={errors.email}
                      placeholder="Електронна пошта"
                      validation={validateEmail}
                    />
                    
                    <div className='wrapPassLine'>
                    <InputField
                      name="password"
                      type={showPassword} 
                      // type="password"
                      touched={touched.password}
                      error={errors.password}
                      placeholder="Пароль"
                      validation={validateRequired}
                    />
                      <Button 
                      variant="primary" 
                      id="btn-primary-user" 
                      className="btn btn-primary-look-Login btn-sm" 
                      onClick={(e) => { 
                        if(showPassword === "password") {
                          setShowPassword("text"); 
                          e.target.classList.add("active");
                        } else {
                        setShowPassword("password"); 
                        e.target.classList.remove("active");
                        }}}>
                    </Button>
                    </div>
                    

                    <div className="d-flex btnWrappDiv">
                    <div className="btn-login-conteiner">
                      <Button
                        variant="primary"
                        // className="btn-block"
                        id="btn-login"
                        type="submit"
                        disabled={(touched.email && errors.email) || (touched.password && errors.password)}
                        >
                        Продовжити
                      </Button>
                      </div>
                      <div className="btn-restore-conteiner">
                      {/* <Link to="/account/signup">Створити обліковий запис </Link> /  */}
                        {/* variant="outline-success" className="me-2 form-group-bar-search-button border-left-0 border" */}
                      <Button
                        variant="edit" 
                        // className="btn-block"
                        id="btn-password-reset"
                        type="button"
                        disabled={(touched.email && errors.email)}
                        onClick={handleSubmitReset}
                        >
                        Забули пароль?
                      </Button>
                      <ResettingForm />
                      </div>
                    </div>
                    
                  </Form>
                )}
                  </Formik>
          </Card.Body>
        </Card>
        </div>
      </div>
  );
}

export default Login;
