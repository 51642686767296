import  moment from 'moment';

  function NormalizeDateForSystem(dateTime) {
    const date = moment(dateTime, 'DD.MM.YYYY').format('YYYY-MM-DD');
    return date;
  }
  
  function NormalizeDateView(dateTime) {
    const date = moment(dateTime, 'YYYY-MM-DD').format('DD.MM.YYYY');
    return date;
  }
  
  
  export { NormalizeDateView, NormalizeDateForSystem };
  