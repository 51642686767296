import { Field } from "formik";
import { FormGroup, FormLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import './InputField.css';

function InputField(props) {
    return (
        <FormGroup>
            <div className="MuiInput-underline MuiInput-root ">
            {props.displayName?<FormLabel htmlFor={props.name}>{props.displayName || props.name}:</FormLabel>: <></>}
            <Field className='form-control rounded-0' type={props.type} name={props.name} id={props.name} placeholder={props.placeholder} validate={props.validation} />
            </div>
            {props.touched && props.error ? <span className='text-danger'>{props.error}</span> : null}
        </FormGroup>
    );
}

export default InputField;