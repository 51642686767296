import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { Container, Modal, Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import CardWrapper from "../../components/general/CardWrapper";
import API from "../../services/api";
import './UsersList.css';
import ModalFormAdd from '../../components/Forms/UserModalFormAdd';

function UserList() {
  const [users, setUsers] = useState([]);
  const [fixeTableHead, setFixeTableHead] = useState(null);
  const [text, setText] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [emailDelete, setEmailDelete] = useState();

  const handleClose = () => setShowModal(false);
  const handleShow = (emailD) => {
    setShowModal(true);
    setEmailDelete(emailD);
  }

  useEffect(() => {
    async function fetchData() {
      const response = await API.get("user/all");
      if (response.status === 200) {
        // console.log("users ", response.data);
        setUsers(response.data);
      }
    }

    window.addEventListener("scroll", function (ev) {
      handleScroll(ev);
    }, false);

    fetchData();
  }, []);

  const getAllUser = async ()=>{
    const response = await API.get("user/all");
    if (response.status === 200) {
      // console.log("users ", response.data);
      setUsers(response.data);
    }
  };


  const deleteUser = async(email)=>{
    // console.log("Email ", email);
    // console.log(`${API}email/${email}`);
    // console.log(`email/${email}`);
    const response = await API.delete(`Account/email/${email}`);
    if(response.status===200){
      // console.log("RESPONSE DELETE ", response);
      setText(true);
      setTimeout( async() => {
      await getAllUser();
      }, 2000);
      handleClose();
    }else{
      console.error();
    }
  };

  const handleScroll= (event) =>{
    var scrolled = document.scrollingElement.scrollTop;
    if(scrolled>100){
        setFixeTableHead(true);
      }else{
        setFixeTableHead(false);
      }
  };

  const showTableHead = () => {
    if(fixeTableHead){
      return (
        <div className="page-item-header-fixed">
          <Container>
            <div className="bg-white">
              <CardWrapper title="Користувачі" theme="light">
                <Table sm bordered className="request-item-header">
                  <thead>
                    <tr className="bg-light border-right-left-bottom-0" >
                      <th className="page-users-list-number">№</th>
                      <th className="page-users-list-email">Користувач</th>
                      <th className="page-users-list-email">Email</th>
                      <th className="page-users-list-phone"></th>
                    </tr>
                  </thead>
                </Table>
              </CardWrapper>
            </div> 
          </Container> 
        </div>  
      );
    }
    }

  let countEl = 1;
  return (
    <Container className="shadow-none">
      <div className="mr-3 d-flex my-3 flex-wrap">
        <h4 className="userListTitle">Користувачі</h4>
        {ModalFormAdd()}
      </div>
      <Card>
        <Card.Body>
          {/* <Card.Title>Користувачі</Card.Title> */}
            <CardWrapper theme="light">
            <Table hover responsive="lg">
              {showTableHead()}
              <thead>
                <tr className="bg-light" >
                  <th className="page-users-list-number border-0">№</th>
                  <th className="page-users-list-email border-0">Користувач</th>
                  <th className="page-users-list-email border-0">Email</th>
                  <th className="page-users-list-number border-0"></th>
                </tr>
              </thead>
              <tbody>
                {users && users.map((obj, index) => (
                  <tr key={index}>
                    <td>{countEl++}</td>
                    <td>{obj.userName}</td>
                    <td>{obj.email}</td>
                  {/* <td><span onClick={()=>{deleteUser(obj.email)}} className="deleteBtn">Видалити</span></td> */}
                  <td><span onClick={()=>{handleShow(obj.email);}} className="deleteBtn">Видалити</span></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardWrapper>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Видалення...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Видалити користувача?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="styleBtnDelete cancel" onClick={handleClose}>
            Закрити
          </Button>
          <Button variant="primary" className="styleBtnDelete" onClick={()=>{deleteUser(emailDelete)}}>
            Видалити
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default UserList;