import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CardWrapper from '../../components/general/CardWrapper';
import InputField from '../../components/general/InputField';
import API from '../../services/api';
import './Register.css';
import InputFieldPhone from '../../components/general/InputFieldPhone';
import {
  validateName,
  validatePhone,
  validateEmail,
  validateRequired,
  validatePassword,
} from '../../services/validations';
import look from "../../lib/img/eyeShow.svg";
import lookClose from "../../lib/img/eyeHide.svg";

function Register() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [allPageMessage, setAllPageMessage] = useState(null);
  const [showAllPagMessage, setShowAllPagMessage] = useState(null);
  const [showPassword, setShowPassword] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  const [phone, setPhone] = useState('');
  const history = useHistory();

  const handleSubmit = (values) => {
    if (values.password !== values.confirmPassword) {
      setErrorMessage('Паролі мають співпадати');//Confirm password must be equal the password
      return;
    }

    let phoneNum = phone;
    if(validatePhone(phone)?.length > 0){
      phoneNum = '';
    }
    let showAllPagMessage = "Зачекайте будь ласка...";
    setShowAllPagMessage(showAllPagMessage);
    setAllPageMessage(true);
    document.getElementById("FormikRegister").style.display = "none";
    document.getElementById("btnRegister").style.display = "none";
    console.log(values.name);
    API.post('/account/register', {
        userName: values.name,
        phoneNumber: phoneNum,
        email: values.email,
        password: values.password,
      })
      .then(() => {
        setShowAllPagMessage("На пошту прийшов лист, необхідно перейти за посиланням, щоб підтвердити аккаунт. Якщо лист не прийшов перевірте спам.");
        window.location.reload();
      })
      .catch((error) => {
        let message;
        if(document.getElementById("btnRegister") && document.getElementById("btnRegister").style){
          document.getElementById("btnRegister").style.display = "block";
        }
        if (error.response) {
          message = error.response.data.message;
        } else if (error.request) {
          message = error.request.data;
        }
        setErrorMessage(message);
        if(message && message.includes("відновлення паролю")){
          let showAllPagMessage = "Користувача з вказаною електронною поштою зареєстровано. Скористайтесь функцією відновлення паролю.";
          setShowAllPagMessage(showAllPagMessage);
          setAllPageMessage(true);
          if(document.getElementById("FormikRegister") && document.getElementById("FormikRegister").style){
            document.getElementById("FormikRegister").style.display = "none";
          }
        }else
        {
          setAllPageMessage(false);
          if(document.getElementById("FormikRegister") && document.getElementById("FormikRegister").style){
            document.getElementById("FormikRegister").style.display = "block";
          }
        }
      });
    };

  const checkError = (touched, errors) => {
    return (
      (touched.email && errors.email) ||
      (touched.erdpou && errors.erdpou) ||
      (touched.licenseId && errors.licenseId) ||
      (touched.password && errors.password) ||
      (touched.confirmPassword && errors.confirmPassword)
    );
  };

  const showCardWrapper = () => {
    if(allPageMessage){
      return (
        <CardWrapper>
        <h4>
          {showAllPagMessage}
        </h4>
        <div className="btn-login-conteiner" id="btnRegister">
          <a className="button" id="btn-login" onClick={()=>{setAllPageMessage(false);setErrorMessage(null); document.getElementById("FormikRegister").style.display = "block";}}>ОК</a>
        </div>
      </CardWrapper>
      );
    }
    }
    // className='mw-100 container shadow-none table-responsive'
  const showFormik = () => {
    return (
    <div className='table-responsive'>
      {showCardWrapper()}
      <div id="FormikRegister">
        <Card className="mw-100 ">
          <Card.Body>
            <Formik
                initialValues={{
                  name: '',
                  phone: '',
                  email: '',
                  erdpou: '',
                  licenseId: '',
                  password: '',
                  confirmPassword: '',
                }}
                onSubmit={handleSubmit}
              >
              {({ touched, errors }) => (
                <Form>
                  {errorMessage && <div className="text-danger my-2">{errorMessage}</div>}
                  <InputField
                    name="name"
                    type="name"
                    displayName="ПІБ"
                    touched={touched.name}
                    error={errors.name}
                    placeholder="Введіть прізвище, ім'я та по батькові"
                    validation={validateName}
                  />

                  <InputFieldPhone
                    name="phone"
                    type="phone"
                    displayName="Телефон"
                    touched={touched.phone}
                    error={errors.phone}
                    setPhone={setPhone}
                    phone={phone}
                  />
                  <InputField
                    name="email"
                    type="email"
                    displayName="Електронна пошта"
                    touched={touched.email}
                    error={errors.email}
                    placeholder="Введіть електронну адресу"
                    validation={validateEmail}
                  />

                  <div className='passDiv'>
                  <InputField
                    name="password"
                    type={showPassword} 
                    displayName="Пароль"
                    touched={touched.password}
                    error={errors.password}
                    placeholder="Введіть пароль"
                    validation={validatePassword}
                  />
                  {/* <Button variant="primary" id="btn-primary-user" className="btn btn-primary-look-Login lookAddForm1 btn-sm " onClick={() => { if(showPassword === "password") {setShowPassword("text");} else {setShowPassword("password");} }}></Button> */}
                  {showPassword === "password" ? 
                 <img src={lookClose} width={20} className='lookAddForm1' onClick={()=>{showPassword === "password" ? setShowPassword("text") : setShowPassword("password")} }/>
                 : 
                 <img src={look} width={20} className='lookAddForm1' onClick={()=>{showPassword === "password" ? setShowPassword("text") : setShowPassword("password")} }/>
                }
                  </div>

                  <div className='passDivConfirm'>
                  <InputField 
                    name="confirmPassword"
                    type={showConfirmPassword} 
                    // type="password"
                    displayName="Підтвердьте пароль"
                    touched={touched.confirmPassword}
                    error={errors.confirmPassword}
                    placeholder="Підтвердьте пароль"
                    validation={validateRequired}
                  />
                  {/* <Button variant="primary" id="btn-primary-user" className="btn btn-primary-look-Login lookAddForm2 btn-sm" onClick={() => { if(showConfirmPassword === "password") {setShowConfirmPassword("text");} else {setShowConfirmPassword("password");} }}></Button> */}
                  {showConfirmPassword === "password" ? 
                 <img src={lookClose} width={20} className='lookAddForm2' onClick={()=>{showConfirmPassword === "password" ? setShowConfirmPassword("text") : setShowConfirmPassword("password")} }/>
                 : 
                 <img src={look} width={20} className='lookAddForm2' onClick={()=>{showConfirmPassword === "password" ? setShowConfirmPassword("text") : setShowConfirmPassword("password")} }/>
                }
                  </div>

                  <div className="btn-login-conteiner text-align-left">
                    <Button 
                    variant="primary" 
                    id="btn-register"
                    type="submit" 
                    disabled={checkError(touched, errors)}>
                      Додати
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
        </div>
      </div>
    );
  };

  return (
    <div>
        {showFormik()}
    </div>
  );
}

export default Register;
