import { useState } from 'react';
import { useAuth } from '../general/ProvideAuth';
import { Link } from 'react-router-dom';
import API from '../../services/api';
import MessageFormSend from './MessageFormSend';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router';
// import { Form, Formik } from 'formik';
import { FormGroup, FormLabel } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import {NormalizeDateView} from '../../services/NormalizeDateService';
import Table from 'react-bootstrap/Table';
import './CardView.css';

function ModalFormView(messages) {

  // const [messGroup, setMessGroup] = useState([]);
  const auth = useAuth();
  const [show, setShow] = useState(false);
  const [showMess, setShowMess] = useState({});
  const [selectedFiles, setFiles] = useState(null);
  const [errorMessageSend, setErrorSend] = useState(null);
  const { id } = useParams();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if(!(auth && auth.user)){ return (<></>); }

  const deletePhoto = async (photoId)=>{
    const result = await API.delete(`/file/photo/${photoId}`);
    if(result.status === 200){
      // console.log("Result delete photo ", result);
      window.location.reload();
    }
  };
  
  function ModalForm() {
    return (
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <FormLabel className='ml-2 font-italic'>{showMess.messageText}</FormLabel>
          <Carousel>
          {showMess.messageFotos?.map((eMessFoto) => {
              return (
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={`data:image/jpeg;base64,${eMessFoto.fileIm}`}
                    alt="First slide"
                  />
                </Carousel.Item>
                // <>
                //   <FormGroup className='mx-1'>
                //     <img src={`data:image/jpeg;base64,${eMessFoto.fileIm}`} height="124" />
                //   </FormGroup>
                // </>
                )
            }
            )}
          </Carousel>
        </Modal.Body>
      </Modal>
    );
  }

  if(auth && auth.user){
    return ( <>
        {messages? 
          <FormGroup className='border-top'>
          {ModalForm()}
            <Table className='additional-info-table' responsive="lg">
              <tbody>
              {messages.map((eMess) => {
  // console.log("messagePHOTO ", messages);
                
              return (
                <>
                  <tr>
                  <td colspan="2"> Дата: {NormalizeDateView(eMess.dateInsert)}</td>
                  </tr>
                  {eMess.messages?.map((eMessBox) => {
                    return (
                      <tr>
                        <td colspan="2">
                         <p> {eMessBox.messageText} </p> 
                          {eMessBox.messageFotos?.map((eMessFoto, index) => {
                            return (
                              <div className='d-flex flex-wrap blockFotoBtn' key={index}>
                              <img src={`data:image/jpeg;base64,${eMessFoto.fileIm}`} 
                                className='mx-1 my-1' 
                                height="124" 
                                onClick={() => {setShowMess(eMessBox); handleShow()}}/>
                                <span className='deleteBtnPhoto' onClick={()=>{deletePhoto(eMessFoto.fileMessId)}}>Видалити</span>
                                </div>
                              )
                          }
                          )}  
                         </td>
                      </tr>)
                   }
                  )}
              </>
              )
            }
            )}
            </tbody>
          </Table>
        </FormGroup>
          :''}
        </>);
  }

  return (<></>);
}

export default ModalFormView;
