import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import  Breadcrumbs from '../../services/Breadcrumbs';
import './Registry.css';
import API from '../../services/api';
import CardView from '../../components/Forms/CardView';
import {NormalizeDateView} from '../../services/NormalizeDateService';
import { 
  massagesErrorDoc
} from '../../services/messages';


function Registry() {
  const [refusals, setRefusals] = useState([]);
  const [messages, setMessages] = useState([]);
  const [dataFilds, setDataFilds] = useState([]);
  const { id } = useParams();
  const [errorMessage, setError] = useState(null);

  const auth = useAuth();

  useEffect(() => {
    async function fetchData() {
      try {
        setError("");
        const result = await API.get(`/refusal/${id}`);
        setRefusals(result.data);
        UpdateDataFilds(result.data)
      } catch (error) {
        console.log(error);
        setError(massagesErrorDoc());
      }
        if (auth && auth?.user){
          const result = await API.get(`/user/messagesAndFiles?ParentDocumentId=${id}`);
          setMessages(result.data);
        }
    }

    fetchData();
  }, [id]);
  
  const UpdateDataFilds = (data) => {
    let listFilds = [];
    listFilds.push({ title : "Відмовляється", text: `${data.applicantIdentifier}, ${data.applicantName}, ${data.addressAdminUnit}`});
    if(data.personResponsible) listFilds.push({ title : "Відповідальна особа", text: data.personResponsible});
    listFilds.push({ title : "у видачі дозволу на порушення об'єкту благоустрою", text: data.name});
    listFilds.push({ title : "за адресою", text: data.addressDescription});
    listFilds.push({ title : "підстави", text: data.reasonRefusal});
    listFilds.push({ title : "За підписом", text: data.personSign});
    setDataFilds(listFilds);
  };

  return (
    <div className="App">
      <Container className='mw-100'>
        <Breadcrumbs />
        <CardView type="view" 
                      id={id} 
                      cardTitle={`Відмова № ${refusals.number} від ${NormalizeDateView(refusals?.issued)}`}
                      listFilds={dataFilds} 
                      messages={messages} 
                      errorMessage={errorMessage} 
                    />
      </Container>
    </div>
  );

}

export default Registry;