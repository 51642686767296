import { useState } from 'react';
import { useAuth } from '../general/ProvideAuth';
import { Link } from 'react-router-dom';
import API from '../../services/api';
import MessageFormSend from './MessageFormSend';
import UserProfile from '../../pages/UserProfile/UserProfile';
import RegisterForm from '../../pages/Auth/RegisterForm';
import ConfirmEmail from '../../pages/Auth/ConfirmEmail';
import Modal from 'react-bootstrap/Modal';
import { Form, Button, ModalFooter } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import look from "../../lib/img/eyeShow.svg";
import lookClose from "../../lib/img/eyeHide.svg";
import { nameCheck } from "../../services/allPattern";
import { surNameCheck } from "../../services/allPattern";
import { phoneCheck } from "../../services/allPattern";
import { emailCheck } from "../../services/allPattern";
import { passwordCheck } from "../../services/allPattern";
import "../general/InputField.css";

function ModalFormAdd() {
  const auth = useAuth();

  const [show, setShow] = useState(false);
  const [selectedFiles, setFiles] = useState(null);
  const [errorMessageSend, setErrorSend] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
console.log(data);
const response = await API.post("/account/adduser", data);
if (response.status === 200) {
  setErrorSend(response.text);
  reset();
  console.log("response ", response.data);
  setTimeout(() => {
    handleClose();
  }, 1500);
}
    } catch (error) {
      setErrorSend("Помилка: " + error);
      console.log("response error ", error);

    }
  };

  function ModalForm() {
    return (
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Додати</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {/* <RegisterForm/> */}
        <Form onSubmit={handleSubmit(onSubmit)} className="form_reg">
        <Form.Group className="mb-2" controlId="formBasicName">
        <div className="MuiInput-underline MuiInput-root ">
                <Form.Label className="App-label">Ім'я *</Form.Label>
                <Form.Control
                className='styleInputRadius'
                  type="text"
                  placeholder="Ім'я"
                  {...register("Name", {
                    required: true,
                    validate: (value) => nameCheck(value),
                  })}
                />
                </div>
                {errors.Name && (
                  <Form.Text className="text-danger">
                    Ім'я має містити мінімум дві літери, з першою великою і
                    рештою малих літер. Не більше 50 символів.
                  </Form.Text>
                )}
              </Form.Group>
        <Form.Group className="mb-2" controlId="formBasicSurname">
        <div className="MuiInput-underline MuiInput-root ">
        <Form.Label className="App-label">Прізвище *</Form.Label>
                <Form.Control
                className='styleInputRadius'
                  type="text"
                  placeholder="Прізвище"
                  {...register("Surname", {
                    required: true,
                    validate: (value) => surNameCheck(value),
                  })}
                />
                </div>
                {errors.Surname && (
                  <Form.Text className="text-danger">
                    Прізвище має містити мінімум дві літери, з першою великою і
                    рештою малих літер. Не більше 50 символів.
                  </Form.Text>
                )}
              </Form.Group>
        <Form.Group className="mb-2" controlId="formBasicLastname">
        <div className="MuiInput-underline MuiInput-root ">
        <Form.Label className="App-label">По-батькові *</Form.Label>
                <Form.Control
                className='styleInputRadius'
                  type="text"
                  placeholder="По-батькові"
                  {...register("Lastname", {
                    required: true,
                    validate: (value) => surNameCheck(value),
                  })}
                />
                </div>
                {errors.Lastname && (
                  <Form.Text className="text-danger">
                    По-батькові має містити мінімум дві літери, з першою великою і
                    рештою малих літер. Не більше 50 символів.
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicEmail">
        <div className="MuiInput-underline MuiInput-root ">
                <Form.Label className="App-label">Пошта *</Form.Label>
                <Form.Control
                className='styleInputRadius'
                  type="email"
                  placeholder="Email"
                  {...register("Email", {
                    required: true,
                    validate: (value) => emailCheck(value),
                  })}
                />
                </div>
                {errors.Email && (
                  <Form.Text className="text-danger">
                    Пошта має бути в схожому форматі, Email@email.ua
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-2">
        <div className="MuiInput-underline MuiInput-root ">
                <Form.Label className="App-label">Номер телефону</Form.Label>
                <Form.Control
                className='styleInputRadius'
                  type="text"
                  id="phone"
                  placeholder="+380"
                  {...register("Phone", {
                    required: true,
                    validate: (value) => phoneCheck(value),
                  })}
                />
                </div>
                {errors.Phone && (
                  <Form.Text className="text-danger">
                    Будь ласка, введіть номер телефону у форматі +380 і 9 цифр
                    вашого телефону.
                  </Form.Text>
                )}
              </Form.Group>
              <div className='passWrapperNewFormAdd'>
              <Form.Group className="mb-2">
        <div className="MuiInput-underline MuiInput-root ">
                <Form.Label className="App-label">Пароль *</Form.Label>
                <Form.Control
                className='styleInputRadius'
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="******"
                  {...register("Pass", {
                    required: true,
                    validate: (value) => passwordCheck(value),
                  })}
                />
                </div>
                {errors.Pass && (
                  <Form.Text className="text-danger">
                    Пароль має містити: Не менше 8 символів латинського
                    алфавіту, 1 велику та малу літери, 1 цифру, 1 спеціальний
                    символ.
                  </Form.Text>
                )}
                
              </Form.Group>
              {!showPassword ? 
                 <img src={lookClose} width={20} className='lookAddForm1 addFormLookNew' onClick={()=>{setShowPassword(true)}}/>
                 : 
                 <img src={look} width={20} className='lookAddForm1 addFormLookNew' onClick={()=>{setShowPassword(false)}}/>
                }
              </div>
              <div className=' d-flex w-100 mt-3'>
              <Button type="submit" id="submit" className='form-btnAddUsers'>
                  Додати
                </Button>
              <Button type="submit" className='form-btnAddUsers cancelBtnUser' onClick={handleClose}>
                  Скасувати
                </Button>
              </div>

        </Form>
        </Modal.Body>
        <ModalFooter className='modalFootStyle'>{errorMessageSend ? errorMessageSend : ""}</ModalFooter>
      </Modal>
    );
  }

  if(auth && auth.user){
    return ( <>
          {ModalForm()}
          <Link className="form-btn btnAddUser" onClick={handleShow}>
            Додати
          </Link>
        </>);
  }

  return (<></>);
}

export default ModalFormAdd;
