import { Container } from 'react-bootstrap';
import ModalFormView from './MessagesModalFormView';
import CardWrapper from '../../components/general/CardWrapper';
import ModalFormAdd from '../../components/Forms/MessageModalFormAdd';
import SchemeModalFormView from '../../components/Forms/SchemeModalFormView';
import { Button, Card } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './CardView.css';
import { useState, useEffect } from 'react';

function CardView({ id, cardTitle, listFilds, messages, errorMessage }) {

  const [pathN, setPathN] = useState();

  const pathname = window.location.pathname; 
  const parts = pathname.split('/'); 
  const firstPart = "/"+parts[1]; 
  // console.log("Path name ", firstPart);
   var localPath = localStorage.getItem('localPath');

   useEffect(() => {
    if (firstPart === localPath) {
      setPathN(firstPart);
    } else {
      setPathN('/');
    }
  }, [firstPart, localPath]);

  return (
    <Container className="shadow-none">
      <div className="mr-3 d-flex my-3 header-bar">
        <input name="action" onClick={() =>{window.location.href = pathN; return false;}} className="submit-back submit-style" type="submit" value="⬅"/>
        {ModalFormAdd(id)}
        {SchemeModalFormView(id)}
      </div>
      <Card>
        <Card.Body>
          <Card.Title>{cardTitle}</Card.Title>
          <CardWrapper theme="light">
              {errorMessage && <div className="text-danger">{errorMessage}</div>}
              <Table className='additional-info-table' responsive="lg">
                <tbody>
                  {listFilds?.map((fild) => {
                        return (
                          <tr>
                            {fild.title? <>
                              <td> {fild.title}</td>
                              <td>{fild.text}</td></>
                            : <td colspan="2"> {fild.text}</td>}
                          </tr>
                        )
                      }
                      )}
                </tbody>
              </Table>
              {ModalFormView(messages)}
          </CardWrapper>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CardView;
