import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';


{/* (<div className='form-guest-bg-gradient navbar-guest-title mw-100 d-flex justify-content-center shadow-none align-items-center'>
  <h2 className="p-2 m-2 text-white text-center">Не знайдено</h2>
</div>); */}

function NotFound() {
  return (<div className='form-guest-bg-gradient mw-100 d-flex justify-content-center shadow-none align-items-center'>
  <h2 className="p-2 m-2 text-white text-center">Не знайдено</h2>
</div>);
}

export default NotFound;
