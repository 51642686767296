import React from "react";
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import './Breadcrumbs.css';

const routeConfig = [
    {
      path: "/",
      breadcrumb: 'Головна'
    },
    {
      path: "/dataSet-registry-Issuances",
      breadcrumb: 'Дозволи'
    },
    {
      path: "/dataSet-registry-issuances/doci",
      breadcrumb: 'Дозвіл'
    },
    {
      path: "/dataSet-registry-annulments",
      breadcrumb: 'Анулювання'
    },
    {
      path: "/dataSet-registry-annulments/doca",
      breadcrumb: 'Документ Анулювання'
    },
    {
      path: "/dataSet-registry-refusals",
      breadcrumb: 'Відмови'
    },
    {
      path: "/dataSet-registry-refusals/docr",
      breadcrumb: 'Відмова'
    },
    {
      path: "/dataSet-Diagram-Analysis-Count",
      breadcrumb: 'Динаміка обробки дозволів'
    },
    {
      path: "/dataSet-Diagram-Analysis-Enterprise",
      breadcrumb: 'Розподіл дозволів за заявниками'
    },
    {
      path: "/User",
      breadcrumb: 'Профіль Користувача'
    },
    {
      path: "/Admin/Users",
      breadcrumb: 'Користувачі'
    },
    {
      path: "/Admin",
      breadcrumb: 'Адміністратор'
    }
  ];
  

const Breadcrumbs = withBreadcrumbs(routeConfig)(({ breadcrumbs }) => (
    <React.Fragment>
        <ol className="breadcrumb">
            {breadcrumbs.map(({ breadcrumb, match }) =><li key={match.url}><a href={match.url} >{breadcrumb}</a></li>)}
        </ol>
    </React.Fragment>
  ));
  
export default Breadcrumbs;

