import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
 import  Breadcrumbs from '../../services/Breadcrumbs';
import './Home.css';

function Home() {

  return (
    <div className="App">
      <Container className='mw-100 px-0'>
        <Breadcrumbs />
        <div className="d-flex flex-row justify-content-center">
          <div className="box-container-section my-2 ">
            <div className="d-flex">
            <Row className='row-col row-cols-xl-3 row-cols-l-3 row-cols-md-2 row-cols-sm-1'>
            <Col className='col-12 col-xl-4 col-l-4 col-md-6 col-sm-12'>
              <div className="my-4 item-container-border-top">
                <h2>
                  <Link to="/dataSet-registry-issuances">Дозволи</Link>
                  </h2>
                  <p>Реєстр містить перелік виданих дозволів на порушення об’єктів благоустрою.</p>
              </div>
              </Col>
              <Col className='col-12 col-xl-4 col-l-4 col-md-6 col-sm-12'>

              <div className="my-4 item-container-border-top">
                <h2>
                  <Link to="/dataSet-registry-refusals">Відмови</Link>
                  </h2>
                  <p>Реєстр містить перелік відмов на надання дозволів на порушення об’єктів благоустрою.</p>
              </div>
              </Col>
              <Col className='col-12 col-xl-4 col-l-4 col-md-6 col-sm-12'>

              <div className="my-4 item-container-border-top">
                <h2>
                  <Link to="/dataSet-registry-annulments">Анулювання</Link>
                  </h2>
                  <p>Реєстр містить перелік анулювань дозволів на порушення об’єктів благоустрою</p>
              </div>
              </Col>
              </Row>

            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center diagrams-container">
          <div className="box-container-section my-2">
            <div className="d-flex">
            <Row className='row-col row-cols-xl-3 row-cols-l-3 row-cols-md-2 row-cols-sm-1'>
            <Col className='col-12 col-xl-6 col-l-6 col-md-6 col-sm-12'>

              <div className="my-4 item-container-border-top">
                <h2>
                  <Link to="/dataSet-Diagram-Analysis-Count">Динаміка обробки дозволів</Link>
                  </h2>
                  <p>Реєстр містить перелік виданих дозволів на порушення об’єктів благоустрою.</p>
              </div>
              </Col>

              <Col className='col-12 col-xl-6 col-l-6 col-md-6 col-sm-12'>

              <div className="my-4 item-container-border-top">
                <h2>
                  <Link to="/dataSet-Diagram-Analysis-Enterprise">Розподіл дозволів за заявниками</Link>
                  </h2>
                  <p>Реєстр містить перелік відмов на надання дозволів на порушення об’єктів благоустрою.</p>
              </div>
              </Col>

              </Row>

            </div>
          </div>
        </div>
      </Container>
    </div>

  );

}

export default Home;
