import { Form } from 'react-bootstrap';
import  moment from 'moment';
import { 
  massagesFilterFilds
} from '../../services/messages';
import {NormalizeDateView} from '../../services/NormalizeDateService';

function RegistrySearchBar(
  dateFilterStart, setDateFilterStart,
  dateFilterEnd, setDateFilterEnd, errorFilterMessage, 
  filterMessage, setSearchRequests, searchRequests, filds, filds1, dataGet, LocalStorage, LocalStorageGet, setSearchNumberDoc, searchNumberDoc, handleSubmitClear) {

  var localStorageParams = LocalStorageGet();
  // console.log("SEARCHBARregistryLocalStorage ", localStorageParams);

  const handleSubmitSearch = e => {
console.log(dateFilterStart, dateFilterEnd, searchRequests, searchNumberDoc);

var searchW = searchRequests ? searchRequests.trim() : null;
var searchN = searchNumberDoc ? searchNumberDoc.trim() : null;
    LocalStorage(dateFilterStart, dateFilterEnd, searchW, searchN);
    dataGet(dateFilterStart, dateFilterEnd, searchW, searchN);
  };

  return (
    <div className="header-bar">
      <Form.Group className='form-group-bar justify-content-left flex-wrap'>
        {/* <Form.Control id="filter" as="select" value={selectedStatus} onChange={(e) => setStatus(e.target.value)}> */}
        З <Form.Control 
        type="date" 
        className='mr-4 rounded-0 form-control-element-w10' 
        defaultValue={localStorageParams ? moment(localStorageParams.dateStart, 'DD.MM.YYYY').format('YYYY-MM-DD') : moment(dateFilterStart, 'DD.MM.YYYY').format('YYYY-MM-DD')}
        onChange={(e) => {e.target.value && setDateFilterStart(NormalizeDateView(e.target.value))}}
        /> по
        <Form.Control 
        type="date" 
        className='mr-4 rounded-0 form-control-element-w10' 
        // value={moment(dateFilterEnd, 'DD.MM.YYYY').format('YYYY-MM-DD')}
        defaultValue={localStorageParams ? moment(localStorageParams.dateEnd, 'DD.MM.YYYY').format('YYYY-MM-DD') : moment(dateFilterEnd, 'DD.MM.YYYY').format('YYYY-MM-DD')}
        onChange={(e) =>  {e.target.value && setDateFilterEnd(NormalizeDateView(e.target.value))}}
        />  
        <div className='d-flex flex-wrap searchWrapp'>
          <span data-tooltip = {massagesFilterFilds(filds)}>
          <Form.Control
            type="search"
            placeholder=""
            className="rounded-0 form-group-bar-search mr-4 form-control-element-w16"
            aria-label="Search"
            value={searchRequests}
            onChange={(e) => setSearchRequests(e.target.value)}
            
          /></span>
          <span data-tooltip = {massagesFilterFilds(filds1)}>
          <Form.Control
            type="search"
            placeholder=""
            className="rounded-0 form-group-bar-search mr-4 form-control-element-w16"
            aria-label="Search"
            value={searchNumberDoc}
            onChange={(e) => setSearchNumberDoc(e.target.value)}
            
          /></span>
          <button variant="outline-success" 
            className="form-group-bar-search-button border btnSearchAnull"
            onClick={
              handleSubmitSearch
            }
            ></button>
          <button variant="outline-success" 
            className="RegistryBarClear"
            onClick={()=>{
              handleSubmitClear();
            }
            }
            >X</button>
        </div>
      </Form.Group>
      <div className='ml-3 text-align-left'>
        {filterMessage && <div className="text-secondary filter-text">{filterMessage}</div>}
        {errorFilterMessage && <div className="text-danger filter-text">{errorFilterMessage}</div>}
      </div>
    </div>
  );

}

export default RegistrySearchBar;
