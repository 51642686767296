import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from '../general/PrivateRoute';
// import NavigationBar from '../Navbar/index';
import { useAuth } from '../general/ProvideAuth';
import NotFound from '../../pages/NotFound/NotFound';
import UserProfile from '../../pages/UserProfile/UserProfile';
import { FooterBar, MainNavigationBar, NavigationBar } from '../Navbar/Index';
import './UserLayout.css';

function UserLayout() {
  const auth = useAuth();
  return (
    <>
      {/* <NavigationBar /> */}
      <Switch>
        <PrivateRoute exact path="/">
          {auth.user.role === 'admin' ? <Redirect to="/admin" /> : <Redirect to="/request" />}
        </PrivateRoute>
        <PrivateRoute exact path="/user">
          <NavigationBar />
          <UserProfile />
        </PrivateRoute>
        <Route>
          <NotFound />
        </Route>
      </Switch>
      <FooterBar />
    </>
  );
}

export default UserLayout;
