import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Pagination from './Pagination.jsx';
import './Pagination.css';

function PaginationWrapper( page, pageCount, path, pageSizeList, setPageSize) {
  return (
    <div className="m-2 justify-content-center nav">
      <div className="pagination">
        {pageCount > 1 && 
        <>
          <Pagination page={page ?? 1} pageCount={pageCount} path={path}/>
          <Form.Group className='justify-content-center ml-2'>
            {/* <Form.Control id="filter" as="select" value={selectedStatus} onChange={(e) => setStatus(e.target.value)}> */}
            <Form.Control as="select" placeholder="" aria-label="Search"
              className="me-2 mr-4 rounded-0 form-group-bar-search"
              onChange={(e) => { setPageSize(e.target.value)}}
              > 
              {pageSizeList.map((p) => (
                <option key={p.key} value={p.key}>
                  {p.value}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </>}
      </div>
    </div>
  );
}

export default PaginationWrapper;
