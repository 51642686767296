import { Field } from "formik";
import { FormGroup, FormLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useState } from 'react';
import {
  validatePhone
} from '../../services/validations';

function InputFieldPhone(props) {
  return (
        <FormGroup>
            {props.displayName ? <FormLabel htmlFor={props.name}>{props.displayName || props.name}:</FormLabel>: null}
            <div className="MuiInput-underline MuiInput-root ">
            <PhoneInput
                    inputClass='w-100 rounded-0 form-control'
                    inputProps={{
                        type:props.type,
                        name: props.name,
                        id: props.name,
                      }}
                    country={'ua'}
                    onlyCountries={['ua']}
                    placeholder='+380(__)___ __ __' 
                    value={props.phone}
                    onChange={phone => props.setPhone(phone)}
                  />
            </div>
            {props.touched && props.error ? <span className='text-danger'>{props.error}</span> : null}
            {validatePhone(props.phone)?.length > 0? <span className='text-danger'>{validatePhone(props.phone)}</span> : null}
        </FormGroup>
    );
}

export default InputFieldPhone;