export function loginCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const loginPattern = /^[A-ZА-ЯІЇ][a-zа-яії0-9]{2,14}$/;
    return loginPattern.test(value);
  }
  return error;
}

export function PIBCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const PIBPattern = /^([A-ZА-ЯІЇ][a-zA-Zа-яА-ЯіїІ'\-`]*\s?){1,50}$/u;
    return PIBPattern.test(value);
  }
  return error;
}
export function nameCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const namePattern = /^([A-ZА-ЯІЇ][a-zA-Zа-яіїА-ЯІЇ'\-`]*\s?){1,50}$/u;
    return namePattern.test(value);
  }
  return error;
}
export function nameCheckRegion(value) {
  let error = null;
  if (value && value.length > 0) {
    //const namePattern = /^([A-ZА-ЯІЇ][a-zA-Zа-яіїА-ЯІЇ'\-`]*\s?){1}([a-zA-Zа-яіїА-ЯІЇ'\-`]*\s?){0,49}$/u;
    const namePattern =
      /^[\dA-ZА-ЯІЇ][\da-zA-Zа-яіїА-ЯІЇ'\-` ]{0,48}[\da-zA-Zа-яіїА-ЯІЇ'\-`]$/u;

    return namePattern.test(value);
  }
  return error;
}

export function surNameCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const surNamePattern = /^([A-ZА-ЯІЇ][a-zA-Zа-яА-ЯіїІ'\-`]*\s?){1,155}$/u;
    return surNamePattern.test(value);
  }
  return error;
}

export function lastSurNameCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const lastsurNamePattern = /^([A-ZА-ЯІЇ][a-zA-Zа-яА-ЯіїІ'\-`]*\s?){1,50}$/u;
    return lastsurNamePattern.test(value);
  }
  return error;
}

export function emailCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,25}$/i;
    return emailPattern.test(value);
  }
  return error;
}

export function phoneCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const phonePattern = /^\+380\d{9}$/;
    return phonePattern.test(value);
  }
  return error;
}

export function passwordCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&_]).{8,}$/;
    return passwordPattern.test(value);
  }
  return error;
}

// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/

export function codeCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const codePattern = /^[A-Za-zА-Яа-я0-9ІіЇї]{1,10}$/;
    return codePattern.test(value);
  }
  return error;
}

export function nameRowCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const nameRowPattern = /^([A-ZА-ЯІЇ][a-zA-Zа-яА-ЯіїІ'\-`]*\s?){1,255}$/;
    return nameRowPattern.test(value);
  }
  return error;
}

export function noteCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const notePattern = /^[A-ZА-ЯІЇ][a-zA-Zа-яА-ЯіІї0-9\s\-,'`.!?]{1,255}$/;
    return notePattern.test(value);
  }
  return error;
}

export function contactsCodeCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const contactsCodePattern = /^[A-ZА-ЯІЇ][a-zа-яії0-9]{1,255}$/;
    return contactsCodePattern.test(value);
  }
  return error;
}

export function contactOsobCodeCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const contactCodePattern = /^[A-ZА-ЯІЇ][a-zа-яії0-9]{1,255}$/;
    return contactCodePattern.test(value);
  }
  return error;
}

export function addressCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const cadressPattern = /^[A-ZА-ЯІЇ][a-zA-Zа-яА-ЯіІї0-9\s\-,'`/.!?]{1,255}$/;
    return cadressPattern.test(value);
  }
  return error;
}

export function postCodeCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const postCodePattern = /^[0-9]{1,25}$/;
    return postCodePattern.test(value);
  }
  return error;
}

export function countryCheck(value) {
  let error = null;
  if (value && value.length > 0) {
    const countryPattern = /^([A-ZА-ЯІЇ][a-zA-Zа-яА-ЯіїІ'\-`]*\s?){1,25}$/;
    return countryPattern.test(value);
  }
  return error;
}
