function validateName(value) {
  let error;
  if (!/^[\p{L}'\s-]+$/ui.test(value) && value.length > 0) {
    error = 'Невірне прізвище, ім\'я або по батькові';
  }
  return error;
}

function validatePhone(value) {
  let error;

  if (!(/^[+]+[0-9+]+$/i.test(value) || /^[0-9+]+$/i.test(value) )&& value.length > 0) {
    error = 'Невірний номер телефону';//, формат телефонного номера +38 ___ ___ __ __
  }else if (value.length > 13 ) {
    error = 'Надто довгий';
  }else if (value.length < 12 && value.length > 0) {
    error = 'Занадто короткий';
  }
  return error;
}

function validateEmail(value) {
  let error;
  if (!value) {
    error = "Це поле є обов'язковим";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Невірна адреса електронної пошти';
  }
  return error;
}

function validateRequired(value) {
  let error;
  if (!value) {
    error = "Це поле є обов'язковим";
  }
  return error;
}

function validateRequiredLong(value) {
  let error;
  if (!value) {
    error = "Це поле є обов'язковим";
  }else if (value.length > 500) {
    error = 'Вміст не повинен перевищувати довжину 500 символів';
  }
  return error;
}

function validateERDPOU(value) {
  let error;
  if (!value) {
    error = "Це поле є обов'язковим";
  } else if (value.length < 8 || value.length > 10) {
    error = 'ЄРДПОУ має бути довжиною 8-10';
  }
  return error;
}

function validateMessage(value) {
  let error;
  if (!value) {
    error = "Це поле є обов'язковим";
  } else if (value.length > 7990) {
    error = 'Вміст не повинен перевищувати довжину 7990 символів';
  }
  return error;
}

function validateNumber(value) {
  let error;
  console.log("validateNumber value " + value);
  if (!value) {
    error = "Це поле є обов'язковим";
  } else if (!Number.parseInt(value)) {
    error = 'Поле повинно бути цілим числом';
  } else if (value < 0) {
    error = 'Поле має мати додатне значення';
  }
  return error;
}

function validateNumberInComboList(value) {
  let error;
  console.log("validateNumber value " + value);
  if (!value || value < 0) {
    error = "Це поле є обов'язковим";
  }
  return error;
}

function validatePassword(value) {
  let error;
  if (!value) {
    error = "Це поле є обов'язковим";
  } else if (value.length < 6) {
    error = 'Занадто короткий';
  } else if (value.length > 50) {
    error = 'Надто довгий';
  } else if (value.search(/\d/) === -1) {
    error = 'Пароль повинен містити принаймні 1 цифру';
  } else if (value.search(/[a-z]/) === -1) {
    error = 'Пароль повинен містити принаймні 1 малу літеру';
  } else if (value.search(/[A-Z]/) === -1) {
    error = 'Пароль повинен містити принаймні 1 велику літеру';
  } else if (value.search(/[~!@#$%^&*()_\-{}[\]\\|:;<>,.?/]/) === -1) {
    error = 'Пароль повинен містити принаймні 1 спеціальний символ: ~!@#$%^&*()_-{}[]\|:;<>,.?/';
  }
  return error;
}
//~ ! @ # $ % ^ & * ( ) _ - + = { } [ ] \ | : ; < > , . ? /    !@#$%^&*_    !@#$%^&*_
function validateRequest(values) {
  let error = null;
  if (values.selectedProduct === -1) {
    error = 'Продукт не може бути порожнім';
  } else if (values.selectedLicense === -1) {
    error = 'Ліцензія не може бути порожньою';
  } else if (values.selectedLevel === -1) {
    error = 'Рівень запиту не може бути порожнім';
  } else if (!values.selectedDate || !Date.parse(values.selectedDate)) {
    error = 'Уведіть бажану дату';
  } else if (!values.description || values.description.length < 4) {
    error = 'Опис повинен бути довжиною від 4 або довше';
  }

  return error;
}

function validateAnswerRequest(values) {
  let error = null;
  if (values.status === -1) {
    error = 'Статус не може бути порожнім';
  } else if (!values.processedDate || !Date.parse(values.processedDate)) {
    error = 'Уведіть дату обробки';
  // } else if (!values.description || values.description.length < 4) {
    // error = 'Опис повинен бути довжиною від 4 або довше';
  }

  return error;
}
export { validateName, validateMessage, validatePhone, validateEmail, validateRequired, validateRequiredLong, validateERDPOU, validateNumber, validatePassword, validateRequest, validateAnswerRequest, validateNumberInComboList };
