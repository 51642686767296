import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import { useHistory } from 'react-router';
import './Registry.css';
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from '../../services/api';
import Pagination from '../../components/Pagination/Pagination';
import PaginationWrapper from '../../components/Pagination/PaginationWrapper';
import isFile from '../../lib/img/isFile.svg';
import  moment from 'moment';
import RegistrySearchBar from '../../components/SearchBar/RegistrySearchBar';
import {NormalizeDateView} from '../../services/NormalizeDateService';
import { 
  massagesFilter, 
  massagesErrorFilter,
  massagesErrorFilterNotFound
} from '../../services/messages';
import { Form } from 'react-bootstrap';
import { pageSizeList } from '../../services/PageSize';

function Registry() {
  const [issuances, setIssuances] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dateFilterStart, setDateFilterStart] = useState(moment().startOf('year').format('DD.MM.YYYY'));
  const [dateFilterEnd, setDateFilterEnd] = useState(moment().format('DD.MM.YYYY'));
  const [searchRequests, setSearchRequests] = useState(null);
  const [searchNumberDoc, setSearchNumberDoc] = useState(null);
  const [errorFilterMessage, setErrorFilterMessage] = useState("");
  const [filterMessage, setFilterMessage] = useState("");
  const [styleRow, setStyleRow] = useState(null);
  const [sortRow, setSortRow] = useState("issued");
  const [Ask, setAsk] = useState(true);
  const history = useHistory();
  const { page } = useParams();
  const filds = ["Номер документа", "Код ЄДРПОУ", "Заявник", "Роботи", "Об'єкт благоустрою", "Адреса об’єкту"];
  const filds1 = "Номер документа";
  
  const auth = useAuth();

  const pathname = window.location.pathname; 
  const parts = pathname.split('/'); 
  const firstPart = parts[1]; 
  localStorage.setItem('localPath', "/"+firstPart);


  useEffect(() => {
    async function fetchData() {
      if (page =="doci") {
        history.push(`/dataSet-registry-issuances`);
      }
      const params = LocalStorageGet();
      if(params!== null){
        const start = params.dateStart;
        const end = params.dateEnd;
        const search = params.searchWords;
        await dataGet(start, end, search, searchNumberDoc);
      }else{
        await dataGet(dateFilterStart, dateFilterEnd);
      }
      // console.log("useEffect getData ", params);
    }

    fetchData();
  }, [page,pageSize, Ask]);

  useEffect(()=>{
    if (window.location.href.includes("dataSet-registry-issuances")) {
      localStorage.removeItem('sortRef');
      localStorage.removeItem('sortAnul');
      localStorage.removeItem('sortRefSelect');
      localStorage.removeItem('sortAnulSelect');
      localStorage.removeItem('SortIsAskRefus');
      localStorage.removeItem('SortIsAskAnul');
      var sort = localStorage.getItem('sortIss');
      var stlyeId = localStorage.getItem('sortIssSelect');
      var isAsk = localStorage.getItem('SortIsAskIssue');
      if (isAsk === "true") {
        setAsk(true);
      } else {
        setAsk(false);
      }
      if(sort){
        var sortR = JSON.parse(sort);
        setSortRow(sortR);
      } else {
        setSortRow("issued");
      }
      if(stlyeId){
        var styleR = JSON.parse(stlyeId);
        setStyleRow(styleR);
      } else {
        setStyleRow(null);
      }
  } 
  }, []);


  const setSortLocalStor = (id)=>{
    var numbId = id;
    var sortR = sortRow;
    var isAsk = Ask;
    sortR = JSON.stringify(sortR);
    numbId = JSON.stringify(numbId);
    isAsk = JSON.stringify(isAsk);
    localStorage.setItem('sortIss', sortR);
    localStorage.setItem('sortIssSelect', numbId);
    localStorage.setItem('SortIsAskIssue', isAsk);
  };

  async function dataGet(dateFilterStart, dateFilterEnd, searchRequests, numberDoc) {

    const result = await API.get(`/issuance?SortField=${sortRow}`, {
      params: {
        page: page,
        pageSize: pageSize,
        dateStart: dateFilterStart,
        dateEnd: dateFilterEnd,
        searchWords: searchRequests,
        numberDoc: numberDoc,
        isAsk: Ask
    }
    })
    .then((res) => {
    // console.log("ResulSearch ", res);

      setFilterMessage(massagesFilter(
        dateFilterStart,
        dateFilterEnd,
        searchRequests));
      setErrorFilterMessage("");
      if (res?.data) return res?.data;
      else return [];
    }).catch((error) => {
      if (error && (error?.response?.status && error?.response?.status == 404)) {
        setErrorFilterMessage(massagesErrorFilterNotFound());
      }else{
        setErrorFilterMessage(massagesErrorFilter());
      }
      setFilterMessage("");
      return [];
    });

    if (result && result.length > 0) {
      setIssuances(result);
      console.log("result files ", result);
      
      const countResult = await API.get('/issuance/count', {
        params: {
          dateStart: dateFilterStart,
          dateEnd: dateFilterEnd,
          searchWords: searchRequests,
          numberDoc: numberDoc
        },
      });
      if (countResult.data) {
    console.log("Count ", countResult.data);
        setPageCount(Math.ceil(countResult.data.count / pageSize));
    console.log("page after search ", pageCount);

      }
    }else{
      setIssuances([]);
      setPageCount(0);
    }
  };

  const handleSelect = (id) => {
    history.push(`/dataSet-registry-issuances/doci/${id}`);
  };

  const LocalStorage = (dataStart, dataEnd, search)=>{
   var params = {
          dateStart: dataStart,
          dateEnd: dataEnd,
          searchWords: search
    };
     params = JSON.stringify(params);
    localStorage.setItem('DozvolyParams', params);
  };

  const LocalStorageGet =()=>{
    var paramsDozvoly = localStorage.getItem('DozvolyParams');
    var params = JSON.parse(paramsDozvoly);
    return params;
  };

  const handleSubmitClear = async e => {
    localStorage.removeItem('DozvolyParams');
    window.location.reload();
  };

  return (
    <div className="App">
      <Container className='mw-100'>
        <Breadcrumbs />
        <div className="d-flex flex-column">
          <div className="header-bar">
            <div className="header-text">
              <p>Дозволи</p>
            </div>
          </div>
          {RegistrySearchBar(dateFilterStart, setDateFilterStart,
                dateFilterEnd, setDateFilterEnd, errorFilterMessage, 
                filterMessage, setSearchRequests, searchRequests, filds, filds1, dataGet, LocalStorage, LocalStorageGet, setSearchNumberDoc, searchNumberDoc, handleSubmitClear)}
          <div className="mr-3 ml-3 my-2">
            <div className="my-2">
              <table className="table-responsive">
                <thead>
                  <tr>
                    {auth && auth.user? 
                    <th className="wd-th-small-3"><div className='column-th'>В</div></th>:<></> }
                    <th className="wd-th-small"><div className='column-th thStyleSort' onClick={()=>{setSortRow("number"); setAsk(!Ask);}}><span className={sortRow === "number" ? "orangeStR" : "noneStR"}>{!Ask? "↓":"↑" }</span> Номер документа </div></th>
                    <th className="wd-th-small"><div className='column-th thStyleSort' onClick={()=>{setSortRow("issued"); setAsk(!Ask);}}><span className={sortRow === "issued" ? "orangeStR" : "noneStR"}>{Ask? "↑": "↓"}</span> Дата документа</div></th>
                    <th className="wd-th-small"><div className='column-th'>Код ЄДРПОУ</div></th>
                    <th className="wd-th-middle"><div className='column-th'>Заявник</div></th>
                    <th className="wd-th-big-vh10"><div className='column-th'>Роботи</div></th>
                    <th className="wd-th-big-vh6"><div className='column-th'>Об'єкт благоустрою</div></th>
                    <th className="wd-th-big-vh6"><div className='column-th'>Адреса об’єкту</div></th>
                    <th className="wd-th-small"><div className='column-th'>Район робіт</div></th>
                    <th className="wd-th-middle"><div className='column-th'>Дата початку дії документа</div></th>
                    <th className="wd-th-middle"><div className='column-th'>Дата закінчення дії документа</div></th>
                  </tr>
                </thead>
                <tbody>
                  {issuances.map((i) => (
                    <tr key={i.id} className={styleRow === i.id ? 'hoverStyleIssuaces selectedRow' : 'hoverStyleIssuaces'} onClick={()=>{setStyleRow(i.id);}}>
                      {auth && auth.user? <td> {i.existsFile == true && <img src={isFile} height="24px" width="24px"/>} </td>:<></> }
                      <td className="registry-item-click" onClick={() =>{setSortLocalStor(i.id); handleSelect(i.parentDocument)}}>{i.number}</td>
                      <td>{NormalizeDateView(i.issued)}</td>
                      <td>{i.applicantIdentifier}</td>
                      <td>{i.applicantName}</td>
                      <td>{i.type}</td>
                      <td>{i.name}</td>
                      <td>{i.addressDescription}</td>
                      <td>{i.addressAdminUnit}</td>
                      <td>{NormalizeDateView(i.validFrom)}</td>
                      <td>{NormalizeDateView(i.validThrough)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {PaginationWrapper(page, pageCount, "/dataSet-registry-issuances/", pageSizeList, setPageSize)}
              </div>
          </div>
        </div>
      </Container>
    </div>
  );

}

export default Registry;
