import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import './NavigationBar.css';
import UserWidget from '../general/UserWidged';

function MainNavigationBar() {
  return (
    <div className='header-bar'>
      <Container className='mw-100 mainContainer'>
        <div className='form-guest-bg-gradient'>
          <Navbar variant="light" expand="lg" className="">
            <Container className='mw-100 shadow-none '>
              <Navbar.Toggle aria-controls="navbar-expanded" />
              <Navbar.Collapse id="navbar-expanded">
                <Nav className="mr-auto">
                  <NavLink to="/dataSet-registry-issuances" exact className="nav-guest-item" activeClassName="active">
                    Дозволи 
                  </NavLink>
                  <NavLink to="/dataSet-registry-refusals" className="nav-guest-item" activeClassName="active">
                    Відмови 
                  </NavLink>
                  <NavLink to="/dataSet-registry-annulments" className="nav-guest-item" activeClassName="active">
                    Анулювання
                  </NavLink>
                </Nav>
                <UserWidget />
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container className='navbar-guest-title'>
              <Link to="/" className='text-center '>
                <h1 className='mainTitle'>Порушення об'єктів благоустрою</h1>
              </Link>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default MainNavigationBar;
