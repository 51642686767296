import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from '../general/PrivateRoute';
import { FooterBar, MainNavigationBar, NavigationBar } from '../Navbar/Index';
import { useAuth } from '../general/ProvideAuth';
import NotFound from '../../pages/NotFound/NotFound';
import UserProfile from '../../pages/UserProfile/UserProfile';
import Home from '../../pages/Main/Home';
import Issuances from '../../pages/Registry/RegistryIssuances';
import IssuanceView from '../../pages/Registry/RegistryIssuanceView';
import Refusals from '../../pages/Registry/RegistryRefusals';
import RefusalView from '../../pages/Registry/RegistryRefusalView';
import Annulments from '../../pages/Registry/RegistryAnnulments';
import AnnulmentView from '../../pages/Registry/RegistryAnnulmentView';
import DiagramAnalysisCount from '../../pages/Diagram/DiagramAnalysisCount';
import DiagramAnalysisEnterprise from '../../pages/Diagram/DiagramAnalysisEnterprise';
import './MainLayout.css';

function MainLayout() {
  const auth = useAuth();
  return (
    <>
      <Switch>
        <Route exact path="/">
          <MainNavigationBar />
          <Home />
        </Route>
        <Route exact path="/dataSet-registry-issuances">
          <NavigationBar />
          <Issuances />
        </Route>
        <Route exact path="/dataSet-registry-issuances/:page?">
          <NavigationBar />
          <Issuances />
        </Route>
        <Route exact path="/dataSet-registry-issuances/doci/:id?">
          <NavigationBar />
          <IssuanceView />
        </Route>
        <Route exact path="/dataSet-registry-refusals">
          <NavigationBar />
          <Refusals />
        </Route>
        <Route exact path="/dataSet-registry-refusals/:page?">
          <NavigationBar />
          <Refusals />
        </Route>
        <Route exact path="/dataSet-registry-refusals/docr/:id?">
          <NavigationBar />
          <RefusalView />
        </Route>
        <Route exact path="/dataSet-registry-annulments">
          <NavigationBar />
          <Annulments />
        </Route>
        <Route exact path="/dataSet-registry-annulments/:page?">
          <NavigationBar />
          <Annulments />
        </Route>
        <Route exact path="/dataSet-registry-annulments/doca/:id?">
          <NavigationBar />
          <AnnulmentView />
        </Route>
        <Route exact path="/dataSet-Diagram-Analysis-Count">
          <NavigationBar />
          <DiagramAnalysisCount />
        </Route>
        <Route exact path="/dataSet-Diagram-Analysis-Enterprise">
          <NavigationBar />
          <DiagramAnalysisEnterprise />
        </Route>
        <Route>
          <NavigationBar />
          <div className='App'>
            <NotFound />
          </div>
        </Route>
      </Switch>
      <FooterBar />
    </>
  );
}

export default MainLayout;
