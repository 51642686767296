function massagesFilter(dataStart,dataEnd,text) {
  let message;
  if (dataStart && dataEnd) {
    message = `Дані відображені  з ${dataStart} по ${dataEnd}`;
    if(text) message += ` та містять "${text}"`;
  }
  return message;
}

function massagesFilterFilds(filds) {
  let message;
  if (filds === Array && filds.length > 0) {
    message = `Пошук даних відбувається в полях: `;
    for (let index = 0; index < filds.length; index++) {
      message += filds[index];
      if(index != (filds.length-1)) message += ', ';
    }
  } else {
    message = `Пошук даних відбувається в полі: `;
    message += filds;
  }
  return message;
}

function massagesErrorFilter() {
  let error;
  error = 'Введено невірні параметри для фільтрації даних';
  return error;
}

function massagesErrorFilterNotFound() {
  let error;
  error = 'Для обраних параметрів фільтрації записів не знайдено';
  return error;
}

function massagesErrorDoc() {
  let error;
  error = 'Виникла помилка при вивавантаженні інформації';
  return error;
}

function massagesCompHintsDiagram(countR, portal, countRPercent, portalPercent) {
  let mass;
  mass = `${countR} -  поданих заяв  \n  (${countRPercent}% від загальної кількості)
  ${portal} - поданих заяв через протал Віза  \n (${portalPercent}% від загальної кількості через протал Віза) `;
  return mass;
}

export { massagesFilter, massagesFilterFilds, massagesErrorFilter, massagesErrorFilterNotFound, massagesErrorDoc, massagesCompHintsDiagram };
