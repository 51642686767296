import axios from 'axios';
// TODO: set from environment variables
// Development
// export const apiUrl = 'http://localhost:5000';
// Production
// export const apiUrl = process.env.NODE_ENV === 'development' ? 'https://localhost:5001' : '';
// export const apiUrl = 'http://supportkdoc.kai.ua';
export const apiUrl = 'https://permitlandscaping.viza.kr.gov.ua';
// export const apiUrl = 'https://supportkdoc.kai.ua';

const API = axios.create({
  baseURL: apiUrl + '/api/',
});

export default API;
