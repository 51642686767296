import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './NavigationBar.css';
import home from '../../lib/img/home_icon.svg';
import UserWidget from '../general/UserWidged';

function NavigationBar() {
  return (
    <Navbar variant="light" expand="lg" className="">
      <Container className='mw-100 shadow-none'>
        <Navbar.Toggle aria-controls="navbar-expanded" />
        <Navbar.Collapse id="navbar-expanded">
          <Nav className="mr-auto ">
            <NavLink to="/" exact className="nav-guest-item home-image" activeClassName="active">
              <img src={home} height="24px" width="24px"/> 
            </NavLink>
            <NavLink to="/dataSet-registry-issuances" exact className="nav-guest-item" activeClassName="active">
              Дозволи 
            </NavLink>
            <NavLink to="/dataSet-registry-refusals" className="nav-guest-item" activeClassName="active">
              Відмови 
            </NavLink>
            <NavLink to="/dataSet-registry-annulments" className="nav-guest-item" activeClassName="active">
            Анулювання
            </NavLink>
          </Nav>
          <UserWidget />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
