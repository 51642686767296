import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../general/ProvideAuth';
import { Link } from 'react-router-dom';
import API from '../../services/api';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { MimeTypes } from '../../services/MimeTypes';

function SchemeModalFormView(ParentDocumentId) {

  const auth = useAuth();

  const [show, setShow] = useState(false);
  const [schemes, setSchemes] = useState(null);
  const [errorMessage, setError] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [showMess, setShowMess] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        setError("");
        const result = await API.get(`/user/schemes?ParentDocumentId=${ParentDocumentId}`);
        setSchemes(result.data);
      } catch (error) {
        console.log(error);
        // setError(massagesErrorDoc());
      }
    }

    fetchData();
  }, [ParentDocumentId]);
  
  async function getFiles(schemeId) {
    try {
      if(schemeId<0)return;
      setError("");
      const result = await API.get(`/user/schemeFiles?SchemeId=${schemeId}`);
      {result.data?.map((selectedFile) => {
        const fileWindow = window.open();
        let object = '  ';
        for (let index = 0; index < MimeTypes.length; index++) {
          const url = 'data:'+MimeTypes[index].value+';base64,' + selectedFile.fileIm;

          if (MimeTypes[index].type == selectedFile.fileExt) {
            if (MimeTypes[index].value.includes("image")) {
              object = '<img type="'+MimeTypes[index].value+'" src="'+ encodeURI(url)  + '"/>';
            }else{
              if (selectedFile.fileExt.includes("doc")) {
                downloadPDF(encodeURI(url), selectedFile.fileName);
                handleClose();
                return;
              }else{
                object = '<object width="100%" width="-webkit-fill-available" height="100%" height="-webkit-fill-available" type="'+MimeTypes[index].value+'" data="' + encodeURI(url) + '"></object>';
              }
            }
            break;
          }
        }

        fileWindow.document.write(
          '<title>' + selectedFile.fileName + '</title>' +
          '<body style="margin: 0">' +
          object +
          '<script>' +
          '   window.stop();' + // Цей скрипт зупиняє оновлення сторінки
          '</script>' +
          '</body>'
        );
        handleClose();
      }
      )}
    } catch (error) {
      console.log(error);
    }
  }

  function downloadPDF(file, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = file;
    downloadLink.download = fileName;
    downloadLink.click(); 
  }

  if(!(auth && auth.user)){ return (<></>); }
  
  
  function ModalForm() {
    return (
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Схеми</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="w-100">
            {schemes?.map((scheme) => (
              <div key={scheme.id} className="registry-item-click text-decoration-none mb-2" onClick={() => getFiles(scheme.id)}>
                <div className="scheme-item">{scheme.name_file}</div>
              </div>
            ))}
        </div>
        </Modal.Body>
      </Modal>
    );
  }

  function showSchemes() {
    if (schemes && schemes.length == 1){
      getFiles(schemes[0].id);
      return (<></>);
    }
    else{
      handleShow();
    }
  }

  if(auth && auth.user){
    return ( <>
          {ModalForm()}
          {schemes && <Link className="form-btn ml-2" onClick={showSchemes}>
          Схеми
          </Link>
          }
          
        </>);
  }

  return (<></>);




}

export default SchemeModalFormView;
