import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import './DiagramAnalysisEnterprise.css';
import { Form } from 'react-bootstrap';
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from '../../services/api';
import {getDiagramPieCnap,getDiagramPiePortal} from './DiagramAnalysisEnterprisePie';
import { requestDistricts } from '../../services/requestService';
import companyIm from '../../lib/img/icon-company2.png';
import docIm from '../../lib/img/icon-doc.png';
import  moment from 'moment';
import { Link } from 'react-router-dom';
import { 
  massagesCompHintsDiagram
} from '../../services/messages';
// import {getMyResponsiveBar} from '../../services/BarCanvas';

function Diagram() {
  const [districts, setDistricts] = useState(requestDistricts[0]);//NormalizeDateView(
  const [dateFilter, setDateFilter] = useState(moment().format('DD.MM.YYYY'));
  const auth = useAuth();
  const [diagramData, setDiagramData] = useState({
    countPortal : 0,
    countRec : 0,
    countCnap : 0,
    countPortalPercent : 0,
    countCnapPercent : 0,
    countEnterprise: []
  });

  useEffect(() => {
    async function fetchData() {
      await getData();
    }

    fetchData();
  }, [districts]);

  async function getData() {
    const resultDiagram = await API.get('/guest/AnalysisEnterprise', {
      params: {
        Date : dateFilter,
        AddressAdminUnit: districts
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
      else return [];
    }).catch(() => {
      return [];
    });

    const resultParam = await API.get('/guest/ParamAnalysisEnterprise', {
    })
    .then((res) => {
      if (res?.data) return res?.data;
      else return [];
    }).catch(() => {
      return [];
    });

    if(resultDiagram && resultDiagram[0]) {
      let diagramDataT = {};
      diagramDataT.countPortal = resultDiagram[0].countPortal;
      diagramDataT.countRec = resultDiagram[0].countRec;
      diagramDataT.countCnap = resultDiagram[0].countRec - resultDiagram[0].countPortal;
      diagramDataT.countPortalPercent =(resultDiagram[0].countPortal/resultDiagram[0].countRec*100).toFixed(2);
      diagramDataT.countCnapPercent =(100 - (resultDiagram[0].countPortal/resultDiagram[0].countRec*100)).toFixed(2);
      diagramDataT.countEnterprise = [];
      let countEnterprise = [{
        id:1,
        name:"",
        countEnterprise:resultDiagram[0].countEnterprise1,
        countEnterprisePortal:resultDiagram[0].countEnterprisePortal1,
        countEnterprisePercent:(resultDiagram[0].countEnterprise1/resultDiagram[0].countEnterprise * 100).toFixed(2),
        countEnterprisePortalPercent:(resultDiagram[0].countEnterprisePortal1/resultDiagram[0].countEnterprisePortal * 100).toFixed(2)
      },{
        id:2,
        name:"",
        countEnterprise:resultDiagram[0].countEnterprise2,
        countEnterprisePortal:resultDiagram[0].countEnterprisePortal2,
        countEnterprisePercent:(resultDiagram[0].countEnterprise2/resultDiagram[0].countEnterprise * 100).toFixed(2),
        countEnterprisePortalPercent:(resultDiagram[0].countEnterprisePortal2/resultDiagram[0].countEnterprisePortal * 100).toFixed(2)
      },{
        id:3,
        name:"",
        countEnterprise:resultDiagram[0].countEnterprise3,
        countEnterprisePortal:resultDiagram[0].countEnterprisePortal3,
        countEnterprisePercent:(resultDiagram[0].countEnterprise3/resultDiagram[0].countEnterprise * 100).toFixed(2),
        countEnterprisePortalPercent:(resultDiagram[0].countEnterprisePortal3/resultDiagram[0].countEnterprisePortal * 100).toFixed(2)
      },{
        id:4,
        name:"",
        countEnterprise:resultDiagram[0].countEnterprise4,
        countEnterprisePortal:resultDiagram[0].countEnterprisePortal4,
        countEnterprisePercent:(resultDiagram[0].countEnterprise4/resultDiagram[0].countEnterprise * 100).toFixed(2),
        countEnterprisePortalPercent:(resultDiagram[0].countEnterprisePortal4/resultDiagram[0].countEnterprisePortal * 100).toFixed(2)
      },{
        id:5,
        name:"",
        countEnterprise:resultDiagram[0].countEnterprise5,
        countEnterprisePortal:resultDiagram[0].countEnterprisePortal5,
        countEnterprisePercent:(resultDiagram[0].countEnterprise5/resultDiagram[0].countEnterprise * 100).toFixed(2),
        countEnterprisePortalPercent:(resultDiagram[0].countEnterprisePortal5/resultDiagram[0].countEnterprisePortal * 100).toFixed(2)
      }]
      if(resultParam && resultParam[0]) {
        resultParam.map((e) => {
          for (let index = 0; index < countEnterprise.length; index++) {
            if (e.id == countEnterprise[index].id) {
                countEnterprise[index].name = e.applicantName;
              }
          }
        });
      }

      countEnterprise.map((e) => ( diagramDataT.countEnterprise.push(e) ));
      
      setDiagramData(diagramDataT);
    }

  }

  function getEnterprise() {

    let countEnterpriseArray = diagramData.countEnterprise.sort(function(a, b) {
      return a.countEnterprise - b.countEnterprise;
    });

    let index = countEnterpriseArray.length+1;
    return countEnterpriseArray.reverse().map((e) => {
      index--
      return(  
      <div className="d-flex justify-content-start flex-wrap px-3"  style={{maxWidth:600+"px"}}> 
        <div className="diagrams-container-company-box my-3">
          <span className='tooltip-inner-hint' data-html="true" twipsy-content-set="true" data-tooltip ={massagesCompHintsDiagram(e.countEnterprise, e.countEnterprisePortal, e.countEnterprisePercent, e.countEnterprisePortalPercent)}>
          {index == 1?  
          <img src={companyIm} height={(36) + "px"} width={(36) + "px"} className='mr-2'/> :  
          <img src={companyIm} height={(index *24) + "px"} width={(index *24) + "px"} className='mr-2'/> }
        </span>
        </div>  
        <div style={{maxWidth:500+"px"}}>
          {e.name}
          <div style={{}}>
            {e.countEnterprise}/
            {e.countEnterprisePortal}
          </div>
          <div style={{}}>
            {e.countEnterprisePercent + '%'}/
            {e.countEnterprisePortalPercent + '%'}
          </div>
        </div>
      </div>  
     )});
  }

  return (
    <div className="App width:100%">
      <Container className='mw-100 px-0 w-100'>
        <div className='form-diagram-bg-gradient'>
          <Container className='navbar-guest-title mw-100 d-flex justify-content-center shadow-none align-items-center'>
            <div className='align-middle'>
                <h2>Розгляд заяв на отримання дозволів на порушення об'єктів благоустрою</h2>
            </div>
          </Container>
        </div>
        <Breadcrumbs />
        <div className="d-flex flex-column align-items-center">
          <div className="header-bar">
            <Form.Group className='form-group-bar justify-content-center formWidth'>
              На <Form.Control 
              type="date" 
              className='mr-4 rounded-0 form-control-element-w10' 
              value={moment(dateFilter, 'DD.MM.YYYY').format('YYYY-MM-DD')}
              onChange={(e) =>  {e.target.value && setDateFilter(moment(e.target.value).format('DD.MM.YYYY'), getData())}}
              /> по
              <Form.Control as="select" placeholder="" aria-label="Search"
                className="me-2 mr-4 rounded-0 form-group-bar-search form-control-element-w16"
                onChange={(e) => setDistricts(e.target.value)}
                > 
                <option value="-1" disabled>
                  Виберіть район
                </option>
                {requestDistricts.map((p) => (
                  <option key={p} value={p}>
                    {p}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="my-1">
              <div className="d-flex diagrams-container-box"> 
                  <h4 className='px-2'>Станом з початку року по {dateFilter}</h4>
              </div>
          </div>
          {/* <div className="my-2 col-md-12 col"> */}
            {/* <div className='diagrams-container'> */}
              <div className=" diagrams-row row-cols-xl-2 row-cols-l-2 row-cols-md-2 row-cols-sm-1 row-cols-2 row"> 
                <div className="col-xl-6 col-l-6 col-md-6 col-sm-12 col-12 diagram-number align-items-center justify-content-center"> 
                  <img src={docIm} height="54px" width="54px" className='mr-2'/> 
                  <h4>Подано заяв</h4>
                </div>  
                <div className="col-xl-6 col-l-6 col-md-6 col-sm-12 col-12 diagram-number"> 
                <h1>
                  {diagramData? diagramData.countRec: ""}
                </h1>
                </div>
              </div>
            {/* </div> */}
          {/* </div> */}
          <div className="my-2 w-100 d-flex justify-content-center">
            <div className="d-flex diagrams-container-box align-items-start w-100 row row-cols-l-2 row-cols-xl-2 row-cols-md-1 row-cols-1"> 
              <div className="col-md-6 col-sm-12 col-12 diagram-number text-center px-0"> 
                <div className=' diagrams-container-pie'>
                    {getDiagramPiePortal(diagramData)}
                </div>
                {diagramData?diagramData.countPortalPercent +'%' : ""}
                <h5>Заяв через <Link className='link-section' to={{ pathname: "https://viza.kr.gov.ua" }} target="_blank"> портал Віза</Link></h5>
                {diagramData?diagramData.countPortal: ""}
              </div>  
              <div className="col-md-6 col-sm-12 col-12 px-0 diagram-number text-center"> 
              <div className='diagrams-container-pie'>
                    {getDiagramPieCnap(diagramData)}
              </div>
                {diagramData?diagramData.countCnapPercent +'%': ""}
                <h5>Заяв через ЦНАП</h5>
                  {diagramData?diagramData.countCnap: ""}
              </div>
            </div>
            <div className="d-flex flex-wrap">
              {/* <div className='diagram-legend-bar-container'>
                <div className='d-flex mt-2'><div className='diagram-legend-bar-f'></div>ЦЕНТР АДМІНІСТРАТИВНИХ ПОСЛУГ "ВІЗА" ("ЦЕНТР ДІЇ")</div>
                <div className='d-flex mt-2'><div className='diagram-legend-bar-s'></div>Муніципальні онлайн-послуги</div>
              </div>
              <div className="ml-auto">
                <div className='mt-4 diagrams-container-min'>{getDiagramBarVertical(diagram, dateFilter?.slice(0, 4))}</div>
              </div> */}
            </div>
              {/* <div className="paginator"><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/1" className="active">1</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/2">2</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/3">3</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/4">4</a></span><span>...<a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/722">722</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/723">723</a></span><span><a href="/dataSet-8c6bcb69-7277-4c25-9035-23fca627f08c/724">724</a></span></div> */}
          </div>
          <div className="my-3 d-flex">
            <div className="diagrams-container-box align-items-end justify-content-space-evenly mb-5">    
            {getEnterprise()}
            </div>
          </div>
        </div>
      </Container>
    </div>

  );

}

export default Diagram;
