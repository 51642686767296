// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const axis ={
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40
}
const theme = {
    fontSize: '14px',
};
const Diagram = (data, keys, indexBy,layout,axisTop,axisRight,axisBottom,axisLeft,margin,padding) => (
    <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={margin}
        padding={padding}
        layout={layout}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'purpleRed_green' }}
        axisTop={axisTop}
        axisRight={axisRight}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        enableGridY={false}
        legends={[ ]}
        labelTextColor="white"
        ariaLabel="Diagram"
        theme={theme}
        // theme={{ legends: { text: { fontSize: 40 } } }} 
    />
);

function getMonthName(monthNumber) {
    const date2 = new Date();
    const date = new Date();
    console.log("monthNumber " + monthNumber);
    date.setDate(15);
    date.setMonth(monthNumber - 1);
    console.log("toLocaleString");
    console.log(date.toLocaleString('uk-UK', { month: 'long' }));

    return date.toLocaleString('ua-UA', { month: 'long' });
  }
// default 
const EsoyName ="ЦЕНТР АДМІНІСТРАТИВНИХ ПОСЛУГ \"ВІЗА\" (\"ЦЕНТР ДІЇ\")"; 
const PortalName ="Муніципальні онлайн-послуги"; 
export function getDiagramBarHorizontal(dataResponse = []) {
    const keys = [];
    const data = [];
    const indexBy = "mStr";
    const layout = "horizontal";
    keys.push("");
    keys.push(EsoyName);
    keys.push(PortalName);
    
    dataResponse?.map((element) => { data.push({
        "ЦЕНТР АДМІНІСТРАТИВНИХ ПОСЛУГ \"ВІЗА\" (\"ЦЕНТР ДІЇ\")":element.countEsoy,
        "Муніципальні онлайн-послуги":element.countPortal,
        m:element.m, 
        mStr: getMonthName(element.m)
    })});
    console.log("data");
    console.log(data);
    const marginCustomize ={ top: 30, right: 30, bottom: 30, left: 80 };
    return Diagram(data.reverse(x=>x.m), keys, indexBy, layout, null,null,null,axis, marginCustomize, 0.4);
}

export function getDiagramBarVertical(dataResponse = [], year) {
    const keys = [];
    const data = [];
    const indexBy = "y";
    const layout = "vertical";
    keys.push("");
    keys.push(EsoyName);
    keys.push(PortalName);
    
    console.log("dataResponse");
    console.log(dataResponse);
    const marginCustomize ={ top: 30, right: 30, bottom: 30, left: 20 };

    if(!dataResponse || dataResponse?.length == 0)
        return Diagram([], keys, indexBy, layout, null,null,axis,null, marginCustomize, 0.4);

    let countEsoy_pyNow = 0;
    let countPortal_pyNow = 0;
    let countEsoy_py = 0;
    let countPortal_py = 0;
    countEsoy_py = dataResponse[0].countEsoy_py;
    countPortal_py = dataResponse[0].countPortal_py;

    dataResponse.map((element) => {
        countEsoy_pyNow += element.countEsoy;
        countPortal_pyNow += element.countPortal;
       });
    console.log(countEsoy_pyNow);
    let months = dataResponse.map(x => x.m);
    let monthFirst = Math.min.apply(null, months);
    let monthLast = Math.max.apply(null, months);
    data.length = 0
    let yearOld =0
    if(year){ yearOld = year -1;}
    const yearStr = getMonthName(monthFirst) +" - " + getMonthName(monthLast) +"\n"+ year + " року";
    const yearOldStr = getMonthName(monthFirst) +" - " + getMonthName(monthLast) +"\n"+ yearOld + " року";
    data.push({
        "ЦЕНТР АДМІНІСТРАТИВНИХ ПОСЛУГ \"ВІЗА\" (\"ЦЕНТР ДІЇ\")":countEsoy_py,
        "Муніципальні онлайн-послуги":countPortal_py,
        y:yearOldStr
    },{
        "ЦЕНТР АДМІНІСТРАТИВНИХ ПОСЛУГ \"ВІЗА\" (\"ЦЕНТР ДІЇ\")":countEsoy_pyNow,
        "Муніципальні онлайн-послуги":countPortal_pyNow,
        y:yearStr
    })
    
    return Diagram(data, keys, indexBy, layout, null,null,axis,null, marginCustomize, 0.2);
}
