import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import { useHistory } from 'react-router';
import './Registry.css';
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from '../../services/api';
import Pagination from '../../components/Pagination/Pagination';
import PaginationWrapper from '../../components/Pagination/PaginationWrapper';
import isFile from '../../lib/img/isFile.svg';
import  moment from 'moment';
import RegistrySearchBar from '../../components/SearchBar/RegistrySearchBar';
import {NormalizeDateView} from '../../services/NormalizeDateService';
import { 
  massagesFilter, 
  massagesErrorFilter,
  massagesErrorFilterNotFound
} from '../../services/messages';
import { pageSizeList } from '../../services/PageSize';

function Registry() {
  const [refusals, setRefusals] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { page } = useParams();
  const [dateFilterStart, setDateFilterStart] = useState(moment().startOf('year').format('DD.MM.YYYY'));
  const [dateFilterEnd, setDateFilterEnd] = useState(moment().format('DD.MM.YYYY'));
  const [searchRequests, setSearchRequests] = useState(null);
  const [searchNumberDoc, setSearchNumberDoc] = useState(null);
  const [errorFilterMessage, setErrorFilterMessage] = useState("");
  const [filterMessage, setFilterMessage] = useState("");
  const [styleRow, setStyleRow] = useState(null);
  const [sortRow, setSortRow] = useState("issued");
  const [Ask, setAsk] = useState(true);
  const history = useHistory();
  const filds = ["Номер документа", "Код ЄДРПОУ", "Заявник", "Роботи", "Об'єкт благоустрою", "Адреса об’єкту", "Підстави відмови"];
  const filds1 = "Номер документа";

  const auth = useAuth();

  const pathname = window.location.pathname; 
  const parts = pathname.split('/'); 
  const firstPart = parts[1]; 
  localStorage.setItem('localPath', "/"+firstPart);

  useEffect(() => {
    async function fetchData() {
      if (page =="docr") {
        history.push(`/dataSet-registry-refusals`);
      }
      const params = LocalStorageGet();
      if(params!== null){
        const start = params.dateStart;
        const end = params.dateEnd;
        const search = params.searchWords;
        await dataGet(start, end, search, searchNumberDoc);
      }else{
        await dataGet(dateFilterStart, dateFilterEnd);
      }
    }

    fetchData();
  }, [page,pageSize, Ask]);
  
  useEffect(()=>{
    if (window.location.href.includes("dataSet-registry-refusals")) {
      localStorage.removeItem('sortIss');
      localStorage.removeItem('sortAnul');
      localStorage.removeItem('sortIssSelect');
      localStorage.removeItem('sortAnulSelect');
      localStorage.removeItem('SortIsAskIssue');
      localStorage.removeItem('SortIsAskAnul');
      var sort = localStorage.getItem('sortRef');
      var stlyeId = localStorage.getItem('sortRefSelect');
      var isAsk = localStorage.getItem('SortIsAskRefus');
      if (isAsk === "true") {
        setAsk(true);
      } else {
        setAsk(false);
      }
      if(sort){
        var sortR = JSON.parse(sort);
        setSortRow(sortR);
      } else {
        setSortRow("issued");
      }
      if(stlyeId){
        var styleR = JSON.parse(stlyeId);
        setStyleRow(styleR);
      } else {
        setStyleRow(null);
      }
  } 
  }, []);

  const setSortLocalStor = (id)=>{
    var numbId = id;
    var sortR = sortRow;
    var isAsk = Ask;
    sortR = JSON.stringify(sortR);
    localStorage.setItem('sortRef', sortR);
    numbId = JSON.stringify(numbId);
    localStorage.setItem('sortRefSelect', numbId);
    localStorage.setItem('SortIsAskRefus', isAsk);
  };

  async function dataGet(dateFilterStart, dateFilterEnd, searchRequests, numberDoc) {
    const result = await API.get(`/refusal?SortField=${sortRow}`, {
      params: {
        page: page,
        pageSize: pageSize,
        dateStart: dateFilterStart,
        dateEnd: dateFilterEnd,
        searchWords: searchRequests,
        numberDoc: numberDoc,
        isAsk: Ask
      },
    }).then((res) => {
      setFilterMessage(massagesFilter(
        dateFilterStart,
        dateFilterEnd,
        searchRequests));
      setErrorFilterMessage("");
      if (res?.data) return res?.data;
      else return [];
    }).catch((error) => {
      if (error && (error?.response?.status && error?.response?.status == 404)) {
        setErrorFilterMessage(massagesErrorFilterNotFound());
      }else{
        setErrorFilterMessage(massagesErrorFilter());
      }
      setFilterMessage("");
      return [];
    });

    if (result && result.length > 0) {
      setRefusals(result);
      // console.log("result files ", result);
      
      const countResult = await API.get('/refusal/count', {
        params: {
          dateStart: dateFilterStart,
          dateEnd: dateFilterEnd,
          searchWords: searchRequests,
          numberDoc: numberDoc
        },
      });
      if (countResult.data) {
        setPageCount(Math.ceil(countResult.data.count / pageSize));
      }
    }else{
      setRefusals([]);
      setPageCount(0);
    }

  };

  const handleSelect = (id) => {
    history.push(`/dataSet-registry-refusals/docr/${id}`);
  };

  const LocalStorage = (dataStart, dataEnd, search)=>{
    var params = {
           dateStart: dataStart,
           dateEnd: dataEnd,
           searchWords: search
     };
      params = JSON.stringify(params);
     localStorage.setItem('VidmovyParams', params);
   };
 
   const LocalStorageGet =()=>{
     var paramsVidmovy = localStorage.getItem('VidmovyParams');
     var params = JSON.parse(paramsVidmovy);
     return params;
   };

   const handleSubmitClear = async e => {
    localStorage.removeItem('VidmovyParams');
    window.location.reload();
  };

  return (
    <div className="App">
      <Container className='mw-100'>
        <Breadcrumbs />
        <div className="d-flex flex-column">
          <div className="header-bar">
            <div className="header-text">
              <p>Відмови</p>
            </div>
          </div>
          {RegistrySearchBar(dateFilterStart, setDateFilterStart,
                dateFilterEnd, setDateFilterEnd, errorFilterMessage, 
                filterMessage, setSearchRequests, searchRequests, filds, filds1, dataGet, LocalStorage, LocalStorageGet, setSearchNumberDoc, searchNumberDoc, handleSubmitClear)}
          <div className="mr-3 ml-3 my-2">
            <div className="my-2">
              <table className="table-responsive">
                <thead>
                  <tr>
                    {auth && auth.user? 
                    <th className="wd-th-small-3"><div className='column-th'>В</div></th>:<></> }
                    <th className="wd-th-small"><div className='column-th thStyleSort' onClick={()=>{setSortRow("number"); setAsk(!Ask);}}><span className={sortRow === "number" ? "orangeStR" : "noneStR"}>{!Ask? "↓":"↑" }</span> Номер документа </div></th>
                    <th className="wd-th-small"><div className='column-th thStyleSort' onClick={()=>{setSortRow("issued"); setAsk(!Ask);}}><span className={sortRow === "issued" ? "orangeStR" : "noneStR"}>{Ask? "↑": "↓"}</span> Дата документа</div></th>
                    <th className="wd-th-small"><div className='column-th'>Код ЄДРПОУ</div></th>
                    <th className="wd-th-middle"><div className='column-th'>Заявник</div></th>
                    <th className="wd-th-big-vh10"><div className='column-th'>Роботи</div></th>
                    <th className="wd-th-big-vh6"><div className='column-th'>Об'єкт благоустрою</div></th>
                    <th className="wd-th-big-vh6"><div className='column-th'>Адреса об’єкту</div></th>
                    <th className="wd-th-small"><div className='column-th'>Район робіт</div></th>
                    <th className="wd-th-big-vh10"><div className='column-th'>Підстави відмови</div></th>
                  </tr>
                </thead>
                <tbody>
                  {refusals.map((r) => (
                      <tr key={r.id} className={styleRow === r.id ? 'hoverStyleIssuaces selectedRow' : 'hoverStyleIssuaces'} onClick={()=>{setStyleRow(r.id);}}>
                      {auth && auth.user? <td> {r.existsFile == true && <img src={isFile} height="24px" width="24px"/>} </td>:<></> }
                      <td className="registry-item-click" onClick={() =>{setSortLocalStor(r.id); handleSelect(r.parentDocument)}}>{r.number}</td>
                      <td>{NormalizeDateView(r.issued)}</td>
                      <td>{r.applicantIdentifier}</td>
                      <td>{r.applicantName}</td>
                      <td>{r.type}</td>
                      <td>{r.name}</td>
                      <td>{r.addressDescription}</td>
                      <td>{r.addressAdminUnit}</td>
                      <td>{r.reasonRefusal}</td>
                    </tr>
                  ))}
                </tbody>
                {/* <tr><td>1 </td><td>899297-2022 </td><td>21.10.2022 15:04:27 </td><td>Надання адміністративних послуг </td><td>120-17 </td><td>Надання консультацій (ГАРЯЧА ЛІНІЯ) </td></tr><tr><td>2 </td><td>899294-2022 </td><td>21.10.2022 15:02:50 </td><td>Надання адміністративних послуг </td><td>120-17 </td><td>Надання консультацій (ГАРЯЧА ЛІНІЯ) </td></tr><tr><td>3 </td><td>899276-2022 </td><td>21.10.2022 14:53:47 </td><td>Надання адміністративних послуг </td><td>120-17 </td><td>Надання консультацій (ГАРЯЧА ЛІНІЯ) </td></tr><tr><td>4 </td><td>899271-2022 </td><td>21.10.2022 14:49:34 </td><td>Оформлення паспортів </td><td>120-17 </td><td>Надання консультацій (ГАРЯЧА ЛІНІЯ) </td></tr><tr><td>5 </td><td>899262-2022 </td><td>21.10.2022 14:46:18 </td><td>Оформлення паспортів </td><td>120-17 </td><td>Надання консультацій (ГАРЯЧА ЛІНІЯ) </td></tr><tr><td>6 </td><td>899250-2022 </td><td>21.10.2022 14:38:39 </td><td>Надання адміністративних послуг </td><td>120-17 </td><td>Надання консультацій (ГАРЯЧА ЛІНІЯ) </td></tr> */}
              </table>
              {PaginationWrapper(page, pageCount, "/dataSet-registry-refusals/", pageSizeList, setPageSize)}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );

}

export default Registry;
